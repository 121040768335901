import React, { useState, useEffect, useCallback, createContext } from "react";
import { signRequest } from "utils/utils";
import {
  Tenant,
  Account,
  User,
  Site,
  Floor,
  CategoryTree,
  CategoryPath,
  ProximityRule,
  Asset,
  Tag,
  Map,
  Organization,
  Role,
} from "pages/Provision/Provision.type";
import TenantTable from "pages/Provision/tables/TenantTable";
import AccountTable from "pages/Provision/tables/AccountTable";
import SiteTable from "pages/Provision/tables/SiteTable";
import FloorTable from "pages/Provision/tables/FloorTable";
import CategoryTreeTable from "pages/Provision/tables/CategoryTreeTable";
import CategoryPathTable from "pages/Provision/tables/CategoryPathTable";
import ProximityRuleTable from "pages/Provision/tables/ProximityRuleTable";
import TagTable from "pages/Provision/tables/TagTable";
import AssetTable from "pages/Provision/tables/AssetTable";
import MapTable from "pages/Provision/tables/MapTable";
import UserTable from "pages/Provision/tables/UserTable";
import OrganizationTable from "pages/Provision/tables/OrganizationTable";
import { useParams } from "react-router-dom";
import "pages/Provision/Provision.css";

export const FetchSitesContext = createContext<() => Promise<void>>(() =>
  Promise.resolve(),
);

export const FetchSiteByOperationsContext = createContext<
  (
    accountResName: string,
    siteResName: string,
    operations: string[],
  ) => Promise<void>
>(() => Promise.resolve());

const Provision: React.FC = () => {
  const [tenants, setTenants] = useState<Tenant[]>([]);
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [sites, setSites] = useState<Site[]>([]);
  const [floors, setFloors] = useState<Floor[]>([]);
  const [categoryTrees, setCategoryTrees] = useState<CategoryTree[]>([]);
  const [categoryPaths, setCategoryPaths] = useState<CategoryPath[]>([]);
  const [proximityRules, setProximityRules] = useState<ProximityRule[]>([]);
  const [assets, setAssets] = useState<Asset[]>([]);
  const [tags, setTags] = useState<Tag[]>([]);
  const [maps, setMaps] = useState<Map[]>([]);
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [error] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { tenantParam, accountParam, siteParam, floorOrTreeParam, treeParam } =
    useParams();

  const fetchTenants = useCallback(async () => {
    setTenants([]);
    setLoading(true);
    const url = "/customers";
    const method = "GET";
    const host = "TENANTS";

    try {
      const signedRequest = await signRequest(host, url, method, null);
      const response = await fetch(signedRequest.url, signedRequest);
      const responseData = await response.json();

      setTenants(responseData.results);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchAccounts = useCallback(async () => {
    setAccounts([]);
    setLoading(true);
    const url = "/accounts";
    const method = "GET";
    const host = "TENANTS";

    try {
      const signedRequest = await signRequest(host, url, method, null);
      const response = await fetch(signedRequest.url, signedRequest);
      const responseData = await response.json();

      setAccounts(responseData.results);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchSites = useCallback(async () => {
    setOrganizations([]);
    setUsers([]);
    setSites([]);
    setFloors([]);
    setCategoryTrees([]);
    setCategoryPaths([]);
    setProximityRules([]);
    setAssets([]);
    setTags([]);
    setMaps([]);

    accounts.forEach((account) => {
      fetchSite(account.accountResName, accounts);
      fetchOrganization(account.accountResName, accounts);
      fetchUser(account.accountResName, accounts);
    });
  }, [accounts]);

  const fetchOrganization = useCallback(
    async (accountResName: string, accounts: Account[]) => {
      setLoading(true);

      const url = `/accounts/${accountResName}/organizations?limit=100`;
      const method = "GET";
      const host = "USERS";

      try {
        const signedRequest = await signRequest(host, url, method, null);
        const response = await fetch(signedRequest.url, signedRequest);
        const responseData = await response.json();

        responseData.results.forEach((organization: Organization) => {
          organization.customerResName =
            accounts.find(
              (account) => account.accountResName === accountResName,
            )?.customerResName ?? "";

          organization.accountResName = accountResName;
          setOrganizations((prevOrganizations) => [
            ...prevOrganizations,
            organization,
          ]);
        });
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  const fetchUser = useCallback(
    async (accountResName: string, accounts: Account[]) => {
      setLoading(true);

      const url = `/accounts/${accountResName}/users?limit=1000`;
      const method = "GET";
      const host = "USERS";

      try {
        const signedRequest = await signRequest(host, url, method, null);
        const response = await fetch(signedRequest.url, signedRequest);
        const responseData = await response.json();

        responseData.results.forEach((user: User) => {
          user.customerResName =
            accounts.find(
              (account) => account.accountResName === accountResName,
            )?.customerResName ?? "";

          user.accountResName = accountResName;
          user.role = user.extraProperties?.find((prop) => prop.key === "role")
            ?.value as Role;
          user.allowed_site = JSON.parse(
            user.extraProperties?.find((prop) => prop.key === "allowed_site")
              ?.value ?? "[]",
          );
          user.restricted_site = JSON.parse(
            user.extraProperties?.find((prop) => prop.key === "restricted_site")
              ?.value ?? "[]",
          );

          setUsers((prevUsers) => [...prevUsers, user]);
        });
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  const fetchSite = useCallback(
    async (accountResName: string, accounts: Account[]) => {
      setLoading(true);
      const url = `/accounts/${accountResName}/sites?limit=100`;
      const method = "GET";
      const host = "SITES";

      try {
        const signedRequest = await signRequest(host, url, method, null);
        const response = await fetch(signedRequest.url, signedRequest);
        const responseData = await response.json();

        responseData.results.forEach((site: Site) => {
          site.customerResName =
            accounts.find(
              (account) => account.accountResName === accountResName,
            )?.customerResName ?? "";

          const fetchFloorsPromise = fetchFloors(
            site.accountResName,
            site.siteResName,
          );
          const fetchCategoryTreePromise = fetchCategoryTrees(
            site.accountResName,
            site.siteResName,
          );
          const fetchProximityRulePromise = fetchProximityRules(
            site.accountResName,
            site.siteResName,
          );
          const fetchAssetsPromise = fetchAssets(
            site.accountResName,
            site.siteResName,
          );
          const fetchTagsPromise = fetchTags(
            site.accountResName,
            site.siteResName,
          );

          const fetchMapsPromise = fetchMaps(
            site.accountResName,
            site.siteResName,
          );

          Promise.all([
            fetchFloorsPromise,
            fetchCategoryTreePromise,
            fetchProximityRulePromise,
            fetchAssetsPromise,
            fetchTagsPromise,
            fetchMapsPromise,
          ]);
        });

        setSites((prevSites) => [...prevSites, ...responseData.results]);
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setTimeout(() => setLoading(false), 5000);
      }
    },
    [],
  );

  const fetchSitesFunction = useCallback(
    async (
      accountResName: string,
      siteResName: string,
      operations: string[],
    ) => {
      setLoading(true);
      try {
        operations.forEach((operation) => {
          console.log("operation", operation);
          switch (operation) {
            case "floors":
              setFloors(
                floors.filter((floor) => floor.siteResName !== siteResName),
              );
              fetchFloors(accountResName, siteResName);
              break;
            case "category-trees":
              setCategoryTrees(
                categoryTrees.filter(
                  (categoryTree) => categoryTree.siteResName !== siteResName,
                ),
              );
              setCategoryPaths(
                categoryPaths.filter(
                  (categoryPath) => categoryPath.siteResName !== siteResName,
                ),
              );
              fetchCategoryTrees(accountResName, siteResName);
              break;
            case "proximity-rules":
              setProximityRules(
                proximityRules.filter(
                  (proximityRule) => proximityRule.siteResName !== siteResName,
                ),
              );
              fetchProximityRules(accountResName, siteResName);
              break;
            case "assets":
              setAssets(
                assets.filter((asset) => asset.siteResName !== siteResName),
              );
              fetchAssets(accountResName, siteResName);
              break;
            case "tags":
              setTags(tags.filter((tag) => tag.siteResName !== siteResName));
              fetchTags(accountResName, siteResName);
              break;
            case "maps":
              setMaps(maps.filter((map) => map.siteResName !== siteResName));
              fetchMaps(accountResName, siteResName);
              break;
            default:
              break;
          }
        });
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setTimeout(() => setLoading(false), 1000);
      }
    },
    [floors, categoryTrees, categoryPaths, proximityRules, assets, tags, maps],
  );

  const fetchFloors = useCallback(
    async (accountResName: string, siteResName: string) => {
      const url = `/accounts/${accountResName}/sites/${siteResName}/floors`;
      const method = "GET";
      const host = "SITES";

      try {
        const signedRequest = await signRequest(host, url, method, null);
        const response = await fetch(signedRequest.url, signedRequest);
        const responseData = await response.json();

        responseData?.results.forEach((floor: Floor) => {
          floor.accountResName = accountResName;
          floor.siteResName = siteResName;
        });

        setFloors((prevFloors) => [...prevFloors, ...responseData.results]);
      } catch (error) {
        console.error("Error:", error);
      }
    },
    [],
  );

  const fetchCategoryTrees = useCallback(
    async (accountResName: string, siteResName: string) => {
      const url = `/accounts/${accountResName}/sites/${siteResName}/assets/category-trees?status=active`;
      const method = "GET";
      const host = "ASSETS";

      try {
        const signedRequest = await signRequest(host, url, method, null);
        const response = await fetch(signedRequest.url, signedRequest);
        const responseData = await response.json();

        responseData?.forEach((categoryTree: CategoryTree) => {
          categoryTree.accountResName = accountResName;
          categoryTree.siteResName = siteResName;

          fetchCategoryPaths(
            accountResName,
            siteResName,
            categoryTree.categoryTreeResName,
          );
        });

        setCategoryTrees((prevCategoryTrees) => [
          ...prevCategoryTrees,
          ...responseData,
        ]);
      } catch (error) {
        console.error("Error:", error);
      }
    },
    [],
  );

  const fetchCategoryPaths = useCallback(
    async (
      accountResName: string,
      siteResName: string,
      categoryTreeResName: string,
    ) => {
      const url = `/accounts/${accountResName}/sites/${siteResName}/assets/category-trees/${categoryTreeResName}/categories?status=active&limit=10000`;
      const method = "GET";
      const host = "ASSETS";

      try {
        const signedRequest = await signRequest(host, url, method, null);
        const response = await fetch(signedRequest.url, signedRequest);
        const responseData = await response.json();

        responseData.forEach((categoryPath: CategoryPath) => {
          categoryPath.accountResName = accountResName;
          categoryPath.siteResName = siteResName;
          categoryPath.categoryTreeResName = categoryTreeResName;
        });

        setCategoryPaths((prevCategoryPaths) => [
          ...prevCategoryPaths,
          ...responseData,
        ]);
      } catch (error) {
        console.error("Error:", error);
      }
    },
    [],
  );

  const fetchProximityRules = useCallback(
    async (accountResName: string, siteResName: string) => {
      const url = `/accounts/${accountResName}/sites/${siteResName}/assets/rules/proximity-rules`;
      const method = "GET";
      const host = "ASSETS";

      try {
        const signedRequest = await signRequest(host, url, method, null);
        const response = await fetch(signedRequest.url, signedRequest);
        const responseData = await response.json();

        if (!responseData.results) {
          return;
        }

        responseData.results.forEach((proximityRule: ProximityRule) => {
          proximityRule.accountResName = accountResName;
          proximityRule.siteResName = siteResName;
        });

        setProximityRules((prevProximityRules) => [
          ...prevProximityRules,
          ...responseData.results,
        ]);
      } catch (error) {
        console.error("Error:", error);
      }
    },
    [],
  );

  const fetchAssets = useCallback(
    async (accountResName: string, siteResName: string) => {
      const url = `/accounts/${accountResName}/sites/${siteResName}/assets?limit=10000&status=active`;
      const method = "GET";
      const host = "ASSETS";

      try {
        const signedRequest = await signRequest(host, url, method, null);
        const response = await fetch(signedRequest.url, signedRequest);
        const responseData = await response.json();

        if (!responseData.results) {
          return;
        }

        responseData.results.forEach((asset: Asset) => {
          asset.accountResName = accountResName;
          asset.siteResName = siteResName;
        });

        setAssets((prevAssets) => [...prevAssets, ...responseData.results]);
      } catch (error) {
        console.error("Error:", error);
      }
    },
    [],
  );

  const fetchTags = useCallback(
    async (accountResName: string, siteResName: string) => {
      const url = `/accounts/${accountResName}/sites/${siteResName}/tags/availabilities?limit=10000`;
      const method = "GET";
      const host = "ASSETS";

      try {
        const signedRequest = await signRequest(host, url, method, null);
        const response = await fetch(signedRequest.url, signedRequest);
        const responseData = await response.json();

        if (!responseData.results) {
          return;
        }

        responseData.results.forEach((tag: Tag) => {
          tag.accountResName = accountResName;
          tag.siteResName = siteResName;
        });

        setTags((prevTags) => [...prevTags, ...responseData.results]);
      } catch (error) {
        console.error("Error:", error);
      }
    },
    [],
  );

  const fetchMaps = useCallback(
    async (accountResName: string, siteResName: string) => {
      const url = `/accounts/${accountResName}/sites/${siteResName}/maps`;
      const method = "GET";
      const host = "MAPS";

      try {
        const signedRequest = await signRequest(host, url, method, null);
        const response = await fetch(signedRequest.url, signedRequest);
        const responseData = await response.json();

        if (!responseData.results) {
          return;
        }

        responseData.results.forEach((map: Map) => {
          map.accountResName = accountResName;
          map.siteResName = siteResName;

          fetchMap(map);
        });
      } catch (error) {
        console.error("Error:", error);
      }
    },
    [],
  );

  const fetchMap = useCallback(async (map: Map) => {
    const url = `/accounts/${map.accountResName}/sites/${map.siteResName}/maps/${map.mapResName}/data/upload/s3-object?mapFormat=zip&deployment=alpha`;
    const method = "GET";
    const host = "MAPS";

    try {
      const signedRequest = await signRequest(host, url, method, null);
      const response = await fetch(signedRequest.url, signedRequest);
      const responseData = await response.json();

      if (!responseData) {
        return;
      }
      map.s3BucketName = responseData.s3BucketName;
      map.s3ObjectKey = responseData.s3ObjectKey;

      setMaps((prevMaps) => [...prevMaps, map]);
    } catch (error) {
      console.error("Error:", error);
    }
  }, []);

  useEffect(() => {
    fetchTenants();
    fetchAccounts();
  }, []);

  useEffect(() => {
    fetchSites();
  }, [fetchSites]);

  return (
    <div>
      {error && <div>{error}</div>}
      {loading ? (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      ) : (
        <div>
          {!tenantParam && (
            <FetchSitesContext.Provider value={fetchTenants}>
              <TenantTable
                tenants={tenants}
                accounts={accounts}
                sites={sites}
              />
            </FetchSitesContext.Provider>
          )}
          {tenantParam && !accountParam && (
            <FetchSitesContext.Provider value={fetchAccounts}>
              <AccountTable
                tenant={
                  tenants.filter(
                    (tenant) => tenant.customerResName === tenantParam,
                  )[0]
                }
                accounts={accounts.filter(
                  (account) => account.customerResName === tenantParam,
                )}
                sites={sites}
                users={users}
                organizations={organizations}
              />
            </FetchSitesContext.Provider>
          )}
          {tenantParam && accountParam && !siteParam && (
            <FetchSitesContext.Provider value={fetchAccounts}>
              <SiteTable
                tenant={
                  tenants.filter(
                    (tenant) => tenant.customerResName === tenantParam,
                  )[0]
                }
                account={
                  accounts.filter(
                    (account) => account.accountResName === accountParam,
                  )[0]
                }
                sites={sites.filter(
                  (site) => site.accountResName === accountParam,
                )}
                floors={floors.filter(
                  (floor) => floor.accountResName === accountParam,
                )}
                categoryTrees={categoryTrees.filter(
                  (categoryTree) =>
                    categoryTree.accountResName === accountParam,
                )}
                proximityRules={proximityRules.filter(
                  (proximityRule) =>
                    proximityRule.accountResName === accountParam,
                )}
                assets={assets.filter(
                  (asset) => asset.accountResName === accountParam,
                )}
                tags={tags.filter((tag) => tag.accountResName === accountParam)}
                maps={maps.filter((map) => map.accountResName === accountParam)}
              />
            </FetchSitesContext.Provider>
          )}
          {floorOrTreeParam === "organization" && (
            <FetchSitesContext.Provider value={fetchAccounts}>
              <OrganizationTable
                tenant={
                  tenants.filter(
                    (tenant) => tenant.customerResName === tenantParam,
                  )[0]
                }
                account={
                  accounts.filter(
                    (account) => account.accountResName === accountParam,
                  )[0]
                }
                organizations={organizations.filter(
                  (org) => org.accountResName === accountParam,
                )}
                users={users.filter(
                  (user) => user.accountResName === accountParam,
                )}
              />
            </FetchSitesContext.Provider>
          )}
          {floorOrTreeParam === "user" && (
            <FetchSitesContext.Provider value={fetchAccounts}>
              <UserTable
                tenant={
                  tenants.filter(
                    (tenant) => tenant.customerResName === tenantParam,
                  )[0]
                }
                account={
                  accounts.filter(
                    (account) => account.accountResName === accountParam,
                  )[0]
                }
                sites={sites.filter(
                  (site) => site.accountResName === accountParam,
                )}
                organizations={organizations.filter(
                  (org) => org.accountResName === accountParam,
                )}
                users={users.filter(
                  (user) => user.accountResName === accountParam,
                )}
              />
            </FetchSitesContext.Provider>
          )}
          {floorOrTreeParam === "asset" && (
            <FetchSiteByOperationsContext.Provider value={fetchSitesFunction}>
              <AssetTable
                tenant={
                  tenants.filter(
                    (tenant) => tenant.customerResName === tenantParam,
                  )[0]
                }
                account={
                  accounts.filter(
                    (account) => account.accountResName === accountParam,
                  )[0]
                }
                site={sites.filter((site) => site.siteResName === siteParam)[0]}
                trees={categoryTrees.filter(
                  (categoryTree) => categoryTree.siteResName === siteParam,
                )}
                pathes={categoryPaths.filter(
                  (categoryPath) => categoryPath.siteResName === siteParam,
                )}
                assets={assets.filter(
                  (asset) => asset.siteResName === siteParam,
                )}
                tags={tags.filter((tag) => tag.siteResName === siteParam)}
              />
            </FetchSiteByOperationsContext.Provider>
          )}

          {floorOrTreeParam === "tag" && (
            <FetchSiteByOperationsContext.Provider value={fetchSitesFunction}>
              <TagTable
                tenant={
                  tenants.filter(
                    (tenant) => tenant.customerResName === tenantParam,
                  )[0]
                }
                account={
                  accounts.filter(
                    (account) => account.accountResName === accountParam,
                  )[0]
                }
                site={sites.filter((site) => site.siteResName === siteParam)[0]}
                trees={categoryTrees.filter(
                  (categoryTree) => categoryTree.siteResName === siteParam,
                )}
                pathes={categoryPaths.filter(
                  (categoryPath) => categoryPath.siteResName === siteParam,
                )}
                tags={tags.filter((tag) => tag.siteResName === siteParam)}
              />
            </FetchSiteByOperationsContext.Provider>
          )}
          {floorOrTreeParam === "floor" && (
            <FetchSiteByOperationsContext.Provider value={fetchSitesFunction}>
              <FloorTable
                tenant={
                  tenants.filter(
                    (tenant) => tenant.customerResName === tenantParam,
                  )[0]
                }
                account={
                  accounts.filter(
                    (account) => account.accountResName === accountParam,
                  )[0]
                }
                site={sites.filter((site) => site.siteResName === siteParam)[0]}
                floors={floors.filter(
                  (floor) => floor.siteResName === siteParam,
                )}
              />
            </FetchSiteByOperationsContext.Provider>
          )}
          {floorOrTreeParam === "map" && (
            <FetchSiteByOperationsContext.Provider value={fetchSitesFunction}>
              <MapTable
                tenant={
                  tenants.filter(
                    (tenant) => tenant.customerResName === tenantParam,
                  )[0]
                }
                account={
                  accounts.filter(
                    (account) => account.accountResName === accountParam,
                  )[0]
                }
                site={sites.filter((site) => site.siteResName === siteParam)[0]}
                maps={maps.filter((map) => map.siteResName === siteParam)}
              />
            </FetchSiteByOperationsContext.Provider>
          )}
          {floorOrTreeParam === "ctree" && !treeParam && (
            <FetchSiteByOperationsContext.Provider value={fetchSitesFunction}>
              <CategoryTreeTable
                tenant={
                  tenants.filter(
                    (tenant) => tenant.customerResName === tenantParam,
                  )[0]
                }
                account={
                  accounts.filter(
                    (account) => account.accountResName === accountParam,
                  )[0]
                }
                site={sites.filter((site) => site.siteResName === siteParam)[0]}
                trees={categoryTrees.filter(
                  (categoryTree) => categoryTree.siteResName === siteParam,
                )}
              />
            </FetchSiteByOperationsContext.Provider>
          )}
          {floorOrTreeParam === "ctree" && treeParam && (
            <FetchSiteByOperationsContext.Provider value={fetchSitesFunction}>
              <CategoryPathTable
                tenant={
                  tenants.filter(
                    (tenant) => tenant.customerResName === tenantParam,
                  )[0]
                }
                account={
                  accounts.filter(
                    (account) => account.accountResName === accountParam,
                  )[0]
                }
                site={sites.filter((site) => site.siteResName === siteParam)[0]}
                tree={
                  categoryTrees.filter(
                    (categoryTree) =>
                      categoryTree.categoryTreeResName === treeParam,
                  )[0]
                }
                paths={categoryPaths.filter(
                  (categoryPath) =>
                    categoryPath.categoryTreeResName === treeParam,
                )}
              />
            </FetchSiteByOperationsContext.Provider>
          )}

          {floorOrTreeParam === "prule" && (
            <FetchSiteByOperationsContext.Provider value={fetchSitesFunction}>
              <ProximityRuleTable
                tenant={
                  tenants.filter(
                    (tenant) => tenant.customerResName === tenantParam,
                  )[0]
                }
                account={
                  accounts.filter(
                    (account) => account.accountResName === accountParam,
                  )[0]
                }
                site={sites.filter((site) => site.siteResName === siteParam)[0]}
                trees={categoryTrees.filter(
                  (categoryTree) => categoryTree.siteResName === siteParam,
                )}
                pathes={categoryPaths.filter(
                  (categoryPath) => categoryPath.siteResName === siteParam,
                )}
                rules={proximityRules.filter(
                  (proximityRule) => proximityRule.siteResName === siteParam,
                )}
              />
            </FetchSiteByOperationsContext.Provider>
          )}
        </div>
      )}
    </div>
  );
};

export default Provision;
