import { useState, useContext } from "react";
import { CategoryPath } from "pages/Provision/Provision.type";
import "pages/Provision/Provision.css";
import { signRequest } from "utils/utils";
import { FetchSiteByOperationsContext } from "pages/Provision/Provision";

interface FormErrors {
  categoryPath?: string;
}

interface EditCTreeFormProps {
  edit: boolean;
  selectedPath: CategoryPath;
}

const EditCPathForm: React.FC<EditCTreeFormProps> = ({
  edit,
  selectedPath,
}) => {
  const [path, setPath] = useState<CategoryPath>({
    accountResName: selectedPath.accountResName,
    siteResName: selectedPath.siteResName,
    categoryTreeResName: selectedPath.categoryTreeResName,
    categoryPathResName: selectedPath.categoryPathResName,
    categoryPath: selectedPath.categoryPath,
    categoryStatus: selectedPath.categoryStatus,
  });
  const [error, setError] = useState<string>("");
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [loading, setLoading] = useState<boolean>(false);
  const fetchSites = useContext(FetchSiteByOperationsContext);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setPath({ ...path, [name]: value });
  };

  const patchPath = async () => {
    setError("");
    setLoading(true);
    const url = `/accounts/${selectedPath.accountResName}/sites/${selectedPath.siteResName}/assets/category-trees/${selectedPath.categoryTreeResName}/categories/${selectedPath.categoryPathResName}`;
    const method = "PATCH";
    const host = "ASSETS";

    const body = {
      categoryPath: path.categoryPath,
      categoryStatus: edit ? path.categoryStatus : "inactive",
    };

    try {
      const signedRequest = await signRequest(host, url, method, body);
      const response = await fetch(signedRequest.url, signedRequest);

      if (!response.ok) {
        setError("Failed to update path");
        console.error("Failed to update path", response);
      } else {
        const createdPath = await response.json();
        console.log("Updated path", createdPath);

        createdPath.errorMessage
          ? setError("Failed to update path")
          : fetchSites(selectedPath.accountResName, selectedPath.siteResName, [
              "category-trees",
            ]);
        setLoading(false);
      }
    } catch (error) {
      setError("Failed to update path");
      console.error("Error:", error);
      setLoading(false);
    }
  };

  const validateForm = () => {
    const errors: FormErrors = {};

    if (!path.categoryPath) {
      errors.categoryPath = "Path name is required";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!validateForm()) {
      return;
    }
    await patchPath();
  };

  const editForm = (
    <>
      <label>
        Name:
        <input
          type="text"
          name="categoryPath"
          value={path.categoryPath}
          onChange={handleChange}
        />
      </label>
      {formErrors.categoryPath && (
        <label className="form-error">{formErrors.categoryPath}</label>
      )}
    </>
  );

  const deleteForm = (
    <>
      <label>
        Are you sure you want to inactivate {selectedPath.categoryPath}?
      </label>
    </>
  );

  return (
    <form onSubmit={handleSubmit}>
      <h2>{edit ? "Update" : "Inactivate"} Path</h2>
      {loading && (
        <div className="loader-container">
          <div className="loader" />
        </div>
      )}
      {loading && (
        <label className="post">
          {edit ? "Updating " : "Inactivating "} Path...
        </label>
      )}
      <label className="error">{error}</label>
      {edit ? editForm : deleteForm}
      <input type="submit" value={edit ? "Update Path" : "Inactivate Path"} />
    </form>
  );
};

export default EditCPathForm;
