import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "AuthContext";
import { useLocalStorage } from "react-use";

import "pages/Login/Login.css";

import {
  AuthenticationDetails,
  //CognitoRefreshToken,
  CognitoUser,
  //CognitoUserAttribute,
  CognitoUserPool,
  //CognitoUserSession,
} from "amazon-cognito-identity-js";
import AWS from "aws-sdk";

const {
  VITE_AWS_REGION,
  VITE_AWS_COGNITO_IDENTITY_POOL_ID,
  VITE_AWS_COGNITO_USER_POOL_ID,
  VITE_AWS_COGNITO_USER_POOL_APP_CLIENT_ID,
} = import.meta.env;

const poolData = {
  UserPoolId: VITE_AWS_COGNITO_USER_POOL_ID,
  ClientId: VITE_AWS_COGNITO_USER_POOL_APP_CLIENT_ID,
};

const userPool = new CognitoUserPool(poolData);

const Login: React.FC = () => {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [language, setLanguage] = useState<string>("en");
  const [error, setError] = useState<string>("");
  const navigate = useNavigate();
  const { login } = useAuth();
  const [, setAccessKey] = useLocalStorage("accessKey", "");
  const [, setSecretAccessKey] = useLocalStorage("secretAccessKey", "");
  const [, setSessionToken] = useLocalStorage("sessionToken", "");
  const [, setCognitoUsername] = useLocalStorage("username", "");
  const [, setGroup] = useLocalStorage("group", "");

  const signIn = () => {
    return new Promise<void>((resolve, reject) => {
      const authenticationDetails = new AuthenticationDetails({
        Username: username,
        Password: password,
      });

      const userData = {
        Username: username,
        Pool: userPool,
      };

      const newUser = new CognitoUser(userData);

      newUser.authenticateUser(authenticationDetails, {
        onSuccess: function (result) {
          (AWS.config.region = VITE_AWS_REGION),
            (AWS.config.credentials = new AWS.CognitoIdentityCredentials({
              IdentityPoolId: VITE_AWS_COGNITO_IDENTITY_POOL_ID,
              Logins: {
                [`cognito-idp.${VITE_AWS_REGION}.amazonaws.com/${VITE_AWS_COGNITO_USER_POOL_ID}`]:
                  result.getIdToken().getJwtToken(),
              },
            }));

          (AWS.config.credentials as AWS.Credentials).refresh(
            (error: AWS.AWSError | undefined) => {
              if (error) {
                console.error(error);
                setError(error.message);
                reject(error);
              }
              console.log("Successfully logged in");

              setAccessKey(AWS.config.credentials?.accessKeyId);
              setSecretAccessKey(AWS.config.credentials?.secretAccessKey);
              setSessionToken(AWS.config.credentials?.sessionToken);
              setCognitoUsername(
                result?.getIdToken()?.payload["cognito:username"],
              );
              setGroup(result?.getIdToken()?.payload["cognito:groups"]);

              resolve();
            },
          );
        },
        onFailure: function (err) {
          console.error(err);
          setError(err.message);
          reject(err);
        },
      });
    });
  };

  const handleLogin = async () => {
    if (!username || !password) {
      setError("Username and password are required");
      return;
    }

    try {
      await signIn();
      login();
      navigate("/scrappy/provision");
    } catch (error) {
      setError("Invalid username or password");
    }
  };

  return (
    <div className="login-form">
      <img
        src="https://asset.brandfetch.io/idmxSNqpU3/idS09i35Ev.png"
        alt="Zainar Logo"
      />
      <h1>Welcome to Scrappy</h1>
      {error && <p>{error}</p>}
      <label>
        Username:
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
      </label>
      <label>
        Password:
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </label>
      <label>
        Language:
        <select value={language} onChange={(e) => setLanguage(e.target.value)}>
          <option value="en">English</option>
          <option value="fr">Japanese</option>
        </select>
      </label>
      <label className="rember-me">
        <input
          type="checkbox"
          checked={rememberMe}
          onChange={(e) => setRememberMe(e.target.checked)}
        />
        Remember me
      </label>
      <button onClick={handleLogin}>Login</button>
    </div>
  );
};

export default Login;
