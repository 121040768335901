import { useState, useContext } from "react";
import {
  CategoryPath,
  ProximityRule,
  DayOfWeek,
} from "pages/Provision/Provision.type";
import "pages/Provision/Provision.css";
import { signRequest } from "utils/utils";
import { FetchSiteByOperationsContext } from "pages/Provision/Provision";

interface SiteFormProps {
  edit: boolean;
  selectedProximityRule: ProximityRule;
  pathes: CategoryPath[];
}

function convertEpochToDateString(epochTime: number) {
  const date = new Date(epochTime);
  const year = date.getFullYear().toString();
  const month = ("0" + (date.getMonth() + 1)).slice(-2); // Ensure two digits
  const day = ("0" + date.getDate()).slice(-2); // Ensure two digits
  return `${year}-${month}-${day}`;
}

const EditProximityRuleForm: React.FC<SiteFormProps> = ({
  edit,
  selectedProximityRule,
  pathes,
}) => {
  const [proximityRule, setProximityRule] = useState<ProximityRule>({
    accountResName: selectedProximityRule.accountResName,
    siteResName: selectedProximityRule.siteResName,
    base: {
      ruleResName: selectedProximityRule.base.ruleResName,
      ruleName: selectedProximityRule.base.ruleName,
      ruleEventCategory: selectedProximityRule.base.ruleEventCategory,
      status: selectedProximityRule.base.status,
      creationTime: {
        epochTime: selectedProximityRule.base.creationTime.epochTime,
      },
      lastUpdateTime: {
        epochTime: selectedProximityRule.base.lastUpdateTime.epochTime,
      },
      enableRuleEventNotification:
        selectedProximityRule.base.enableRuleEventNotification,
      operationalPeriod: {
        periodStartDate: {
          epochTime:
            selectedProximityRule.base.operationalPeriod.periodStartDate
              .epochTime,
        },
        periodEndDate: {
          epochTime:
            selectedProximityRule.base.operationalPeriod.periodEndDate
              .epochTime,
        },
        operatingDaysOfWeek:
          selectedProximityRule.base.operationalPeriod.operatingDaysOfWeek,
        operatingDayStartTime:
          selectedProximityRule.base.operationalPeriod.operatingDayStartTime,
        operatingDayEndTime:
          selectedProximityRule.base.operationalPeriod.operatingDayEndTime,
        localTimezoneOffset:
          selectedProximityRule.base.operationalPeriod.localTimezoneOffset,
      },
    },
    ruleType: selectedProximityRule.ruleType,
    assetCategoryA: {
      categoryTreeResName:
        selectedProximityRule.assetCategoryA.categoryTreeResName,
      categoryPathResName:
        selectedProximityRule.assetCategoryA.categoryPathResName,
    },
    assetCategoryB: {
      categoryTreeResName:
        selectedProximityRule.assetCategoryB.categoryTreeResName,
      categoryPathResName:
        selectedProximityRule.assetCategoryB.categoryPathResName,
    },
    redZoneProximityRadius: selectedProximityRule.redZoneProximityRadius,
    yellowZoneProximityRadius: selectedProximityRule.yellowZoneProximityRadius,
    greenZoneProximityRadius: selectedProximityRule.greenZoneProximityRadius,
    assetEntryMinimumDwell: selectedProximityRule.assetEntryMinimumDwell,
    assetExitMinimumDwell: selectedProximityRule.assetExitMinimumDwell,
  });
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const fetchSites = useContext(FetchSiteByOperationsContext);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError("");
    const { name, value } = event.target;
    const keys = name.split(".");

    if (keys.length === 1) {
      if (name.match(/zone|dwell/i)) {
        setProximityRule({ ...proximityRule, [name]: parseInt(value, 10) });
      } else {
        setProximityRule({ ...proximityRule, [name]: value });
      }
    }

    if (keys.length === 2) {
      const [parentKey, childKey] = keys;
      if (parentKey === "base") {
        setProximityRule({
          ...proximityRule,
          base: { ...proximityRule.base, [childKey]: value },
        });
      }
    }

    if (keys.length === 3) {
      const [parentKey, childKey, grandChildKey] = keys;
      if (
        parentKey === "base" &&
        childKey === "operationalPeriod" &&
        (grandChildKey === "periodStartDate" ||
          grandChildKey === "periodEndDate")
      ) {
        const dateParts = value.split("-");
        const year = parseInt(dateParts[0], 10); // Directly parse the full year
        const month = parseInt(dateParts[1], 10) - 1; // Month is 0-indexed in JavaScript Date
        const day = parseInt(dateParts[2], 10);

        const dateInMilliseconds = new Date(year, month, day).getTime();

        setProximityRule({
          ...proximityRule,
          base: {
            ...proximityRule.base,
            operationalPeriod: {
              ...proximityRule.base.operationalPeriod,
              [grandChildKey]: { epochTime: dateInMilliseconds },
            },
          },
        });
      }
    }
  };

  const patchProximityRule = async () => {
    setError("");
    setLoading(true);

    const url = `/accounts/${selectedProximityRule.accountResName}/sites/${selectedProximityRule.siteResName}/assets/rules/proximity-rules/${selectedProximityRule.base.ruleResName}`;
    const method = "PATCH";
    const host = "ASSETS";

    const body = {
      base: {
        ruleName: proximityRule.base.ruleName,
        status: edit ? proximityRule.base.status : "inactive",
        enableRuleEventNotification:
          proximityRule.base.enableRuleEventNotification,
        operationalPeriod: {
          periodStartDate: {
            epochTime:
              proximityRule.base.operationalPeriod.periodStartDate.epochTime,
          },
          periodEndDate: {
            epochTime:
              proximityRule.base.operationalPeriod.periodEndDate.epochTime,
          },
          operatingDaysOfWeek:
            proximityRule.base.operationalPeriod.operatingDaysOfWeek,
          operatingDayStartTime:
            proximityRule.base.operationalPeriod.operatingDayStartTime,
          operatingDayEndTime:
            proximityRule.base.operationalPeriod.operatingDayEndTime,
          localTimezoneOffset:
            proximityRule.base.operationalPeriod.localTimezoneOffset,
        },
        ruleEventCategory: proximityRule.base.ruleEventCategory,
      },
      assetCategoryA: {
        categoryTreeResName: proximityRule.assetCategoryA.categoryTreeResName,
        categoryPathResName: proximityRule.assetCategoryA.categoryPathResName,
      },
      assetCategoryB: {
        categoryTreeResName: proximityRule.assetCategoryB.categoryTreeResName,
        categoryPathResName: proximityRule.assetCategoryB.categoryPathResName,
      },
      redZoneProximityRadius: proximityRule.redZoneProximityRadius,
      yellowZoneProximityRadius: proximityRule.yellowZoneProximityRadius,
      greenZoneProximityRadius: proximityRule.greenZoneProximityRadius,
      assetEntryMinimumDwell: proximityRule.assetEntryMinimumDwell,
      assetExitMinimumDwell: proximityRule.assetExitMinimumDwell,
    };

    try {
      const signedRequest = await signRequest(host, url, method, body);
      const response = await fetch(signedRequest.url, signedRequest);

      if (!response.ok) {
        setError("Failed to create proximity rule");
        console.error("Failed to update proximity rule", response);
      } else {
        const createdProximityRule = await response.json();
        console.log("Updated proximity rule", createdProximityRule);

        createdProximityRule.errorMessage
          ? setError("Failed to update proximity rule")
          : fetchSites(
              selectedProximityRule.accountResName,
              selectedProximityRule.siteResName,
              ["proximity-rules"],
            );
        setLoading(false);
      }
    } catch (error) {
      setError("Failed to update proximity rule");
      console.error("Error:", error);
      setLoading(false);
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await patchProximityRule();
  };

  const editForm = (
    <>
      <label>
        Rule Name:
        <input
          type="text"
          name="base.ruleName"
          value={proximityRule.base.ruleName}
          onChange={handleChange}
        />
      </label>
      <label>
        Rule Event Category:
        <input
          type="text"
          name="base.ruleEventCategory"
          value={proximityRule.base.ruleEventCategory}
          onChange={handleChange}
        />
      </label>
      <label>
        Rule Type:
        <input
          type="text"
          name="ruleType"
          value={proximityRule.ruleType}
          onChange={handleChange}
          disabled={true}
        />
      </label>
      <label>
        Enable Rule Event Notification:
        <input
          type="checkbox"
          name="base.enableRuleEventNotification"
          checked={proximityRule.base.enableRuleEventNotification}
          onChange={(event) =>
            setProximityRule({
              ...proximityRule,
              base: {
                ...proximityRule.base,
                enableRuleEventNotification: event.target.checked,
              },
            })
          }
        />
      </label>
      <label>
        Period Start Date:
        <input
          type="date"
          name="base.operationalPeriod.periodStartDate"
          value={convertEpochToDateString(
            proximityRule.base.operationalPeriod.periodStartDate.epochTime,
          )}
          onChange={handleChange}
        />
      </label>
      <label>
        Period End Date:
        <input
          type="date"
          name="base.operationalPeriod.periodEndDate"
          value={convertEpochToDateString(
            proximityRule.base.operationalPeriod.periodEndDate.epochTime,
          )}
          onChange={handleChange}
        />
      </label>
      <label>
        Select Operating Days:
        <select
          multiple
          value={proximityRule.base.operationalPeriod.operatingDaysOfWeek}
          onChange={(event) => {
            setProximityRule({
              ...proximityRule,
              base: {
                ...proximityRule.base,
                operationalPeriod: {
                  ...proximityRule.base.operationalPeriod,
                  operatingDaysOfWeek: Array.from(
                    event.target.selectedOptions,
                    (option) => option.value as DayOfWeek,
                  ),
                },
              },
            });
          }}
        >
          <option value="Mon">Mon</option>
          <option value="Tue">Tue</option>
          <option value="Wed">Wed</option>
          <option value="Thu">Thu</option>
          <option value="Fri">Fri</option>
          <option value="Sat">Sat</option>
          <option value="Sun">Sun</option>
        </select>
      </label>
      <label>
        Operating Day Start Time (hh:mm):
        <input
          type="text"
          name="base.operationalPeriod.operatingDayStartTime"
          value={proximityRule.base.operationalPeriod.operatingDayStartTime}
          onChange={(event) => {
            const value = event.target.value;
            setProximityRule({
              ...proximityRule,
              base: {
                ...proximityRule.base,
                operationalPeriod: {
                  ...proximityRule.base.operationalPeriod,
                  operatingDayStartTime: value,
                },
              },
            });
          }}
        />
      </label>
      <label>
        Operating Day End Time (hh:mm):
        <input
          type="text"
          name="base.operationalPeriod.operatingDayEndTime"
          value={proximityRule.base.operationalPeriod.operatingDayEndTime}
          onChange={(event) => {
            const value = event.target.value;
            setProximityRule({
              ...proximityRule,
              base: {
                ...proximityRule.base,
                operationalPeriod: {
                  ...proximityRule.base.operationalPeriod,
                  operatingDayEndTime: value,
                },
              },
            });
          }}
        />
      </label>
      <label>
        Local Timezone Offset:
        <input
          type="number"
          name="base.operationalPeriod.localTimezoneOffset"
          value={proximityRule.base.operationalPeriod.localTimezoneOffset}
          onChange={(event) => {
            const value = parseInt(event.target.value, 10);
            setProximityRule({
              ...proximityRule,
              base: {
                ...proximityRule.base,
                operationalPeriod: {
                  ...proximityRule.base.operationalPeriod,
                  localTimezoneOffset: value,
                },
              },
            });
          }}
        />
      </label>
      <label>
        Asset Category A:
        <select
          name="assetCategoryA"
          value={`${proximityRule.assetCategoryA.categoryTreeResName}/${proximityRule.assetCategoryA.categoryPathResName}`}
          onChange={(event) => {
            const [categoryTreeResName, categoryPathResName] =
              event.target.value.split("/");
            setProximityRule({
              ...proximityRule,
              assetCategoryA: {
                categoryTreeResName: categoryTreeResName,
                categoryPathResName: categoryPathResName,
              },
            });
          }}
        >
          <option value="">Select cTree and cPath</option>
          {pathes.map((path) => (
            <option
              key={path.categoryPathResName}
              value={`${path.categoryTreeResName}/${path.categoryPathResName}`}
            >
              {path.categoryPath}
            </option>
          ))}
        </select>
      </label>
      <label>
        Asset Category B:
        <select
          name="assetCategoryB"
          value={`${proximityRule.assetCategoryB.categoryTreeResName}/${proximityRule.assetCategoryB.categoryPathResName}`}
          onChange={(event) => {
            const [categoryTreeResName, categoryPathResName] =
              event.target.value.split("/");
            setProximityRule({
              ...proximityRule,
              assetCategoryB: {
                categoryTreeResName: categoryTreeResName,
                categoryPathResName: categoryPathResName,
              },
            });
          }}
        >
          <option value="">Select cTree and cPath</option>
          {pathes.map((path) => (
            <option
              key={path.categoryPathResName}
              value={`${path.categoryTreeResName}/${path.categoryPathResName}`}
            >
              {path.categoryPath}
            </option>
          ))}
        </select>
      </label>
      <label>
        Red Zone Proximity Radius:
        <input
          type="number"
          name="redZoneProximityRadius"
          value={proximityRule.redZoneProximityRadius}
          onChange={handleChange}
        />
      </label>
      <label>
        Yellow Zone Proximity Radius:
        <input
          type="number"
          name="yellowZoneProximityRadius"
          value={proximityRule.yellowZoneProximityRadius}
          onChange={handleChange}
        />
      </label>
      <label>
        Green Zone Proximity Radius:
        <input
          type="number"
          name="greenZoneProximityRadius"
          value={proximityRule.greenZoneProximityRadius}
          onChange={handleChange}
        />
      </label>
      <label>
        Asset Entry Minimum Dwell:
        <input
          type="number"
          name="assetEntryMinimumDwell"
          value={proximityRule.assetEntryMinimumDwell}
          onChange={handleChange}
        />
      </label>
      <label>
        Asset Exit Minimum Dwell:
        <input
          type="number"
          name="assetExitMinimumDwell"
          value={proximityRule.assetExitMinimumDwell}
          onChange={handleChange}
        />
      </label>
    </>
  );

  const deleteForm = (
    <>Are you sure you want to inactivate {proximityRule.base.ruleName}?</>
  );

  return (
    <form onSubmit={handleSubmit}>
      <h2>{edit ? "Update" : "Inactivate"} Rule </h2>
      {loading && (
        <div className="loader-container">
          <div className="loader" />
        </div>
      )}
      {loading && (
        <label className="post">
          {" "}
          {edit ? "Updating " : "Inactivating"} Rule...
        </label>
      )}
      {error && <label className="error">{error}</label>}
      {edit ? editForm : deleteForm}
      <input type="submit" value={edit ? "Update Rule" : "Inactivate Rule"} />
    </form>
  );
};

export default EditProximityRuleForm;
