import {
  CategoryTree,
  CategoryPath,
  ProximityRule,
} from "pages/Provision/Provision.type";
import "pages/Provision/Provision.css";

interface ProximityRuleDetailsProps {
  account: string;
  site: string;
  trees: CategoryTree[];
  pathes: CategoryPath[];
  selectedRule: ProximityRule;
}

const ProximityRuleDetails: React.FC<ProximityRuleDetailsProps> = ({
  account,
  site,
  trees,
  pathes,
  selectedRule,
}) => {
  return (
    <form>
      <h2>Proximity Rule Details</h2>
      <label>
        <div className="label-value-container">
          <span>Account:</span>
          <span className="value">{account}</span>
        </div>
      </label>
      <label>
        <div className="label-value-container">
          <span>Site:</span>
          <span className="value">{site}</span>
        </div>
      </label>
      <label>
        <div className="label-value-container">
          <span>RuleResName:</span>
          <span className="value">{selectedRule.base.ruleResName}</span>
        </div>
      </label>
      <label>
        <div className="label-value-container">
          <span>Rule Name:</span>
          <span className="value">{selectedRule.base.ruleName}</span>
        </div>
      </label>
      <label>
        <div className="label-value-container">
          <span>Status:</span>
          <span className="value">{selectedRule.base.status}</span>
        </div>
      </label>
      <label>
        <div className="label-value-container">
          <span>enableRuleEventNotification:</span>
          <span className="value">
            {selectedRule.base.enableRuleEventNotification ? "True" : "False"}
          </span>
        </div>
      </label>
      <label>
        <div className="label-value-container">
          <span>periodStartDate:</span>
          <span className="value">
            {" "}
            {new Date(
              selectedRule.base.operationalPeriod.periodStartDate.epochTime,
            ).toLocaleString()}
          </span>
        </div>
      </label>
      <label>
        <div className="label-value-container">
          <span>periodEndDate:</span>
          <span className="value">
            {" "}
            {new Date(
              selectedRule.base.operationalPeriod.periodEndDate.epochTime,
            ).toLocaleString()}
          </span>
        </div>
      </label>
      <label>
        <div className="label-value-container">
          <span>operatingDaysOfWeek:</span>
          <span className="value">
            {selectedRule.base.operationalPeriod.operatingDaysOfWeek.join(", ")}
          </span>
        </div>
      </label>

      <label>
        <div className="label-value-container">
          <span> operatingDayStartTime:</span>
          <span className="value">
            {selectedRule.base.operationalPeriod.operatingDayStartTime}
          </span>
        </div>
      </label>

      <label>
        <div className="label-value-container">
          <span> operatingDayEndTime:</span>
          <span className="value">
            {selectedRule.base.operationalPeriod.operatingDayEndTime}
          </span>
        </div>
      </label>

      <label>
        <div className="label-value-container">
          <span> localTimezoneOffset:</span>
          <span className="value">
            {selectedRule.base.operationalPeriod.localTimezoneOffset}
          </span>
        </div>
      </label>
      <label>
        <div className="label-value-container">
          <span> ruleEventCategory: </span>
          <span className="value">{selectedRule.base.ruleEventCategory}</span>
        </div>
      </label>

      <label>
        <div className="label-value-container">
          <span> ruleType: </span>
          <span className="value">{selectedRule.ruleType}</span>
        </div>
      </label>
      <label>
        <div className="label-value-container">
          <span> assetCategoryA:</span>
          <span className="value">
            {trees.find(
              (tree) =>
                tree.categoryTreeResName ===
                selectedRule.assetCategoryA.categoryTreeResName,
            )?.categoryTreeName || "N/A"}
            ,
            {pathes.find(
              (path) =>
                path.categoryPathResName ===
                selectedRule.assetCategoryA.categoryPathResName,
            )?.categoryPath || "N/A"}
          </span>
        </div>
      </label>
      <label>
        <div className="label-value-container">
          <span> assetCategoryB: </span>
          <span className="value">
            {trees.find(
              (tree) =>
                tree.categoryTreeResName ===
                selectedRule.assetCategoryB.categoryTreeResName,
            )?.categoryTreeName || "N/A"}
            ,
            {pathes.find(
              (path) =>
                path.categoryPathResName ===
                selectedRule.assetCategoryB.categoryPathResName,
            )?.categoryPath || "N/A"}
          </span>
        </div>
      </label>

      <label>
        <div className="label-value-container">
          <span> redZoneProximityRadius: </span>
          <span className="value">{selectedRule.redZoneProximityRadius}</span>
        </div>
      </label>
      <label>
        <div className="label-value-container">
          <span> yellowZoneProximityRadius: </span>
          <span className="value">
            {selectedRule.yellowZoneProximityRadius}
          </span>
        </div>
      </label>
      <label>
        <div className="label-value-container">
          <span> greenZoneProximityRadius: </span>
          <span className="value">{selectedRule.greenZoneProximityRadius}</span>
        </div>
      </label>
      <label>
        <div className="label-value-container">
          <span> assetEntryMinimumDwell: </span>
          <span className="value">{selectedRule.assetEntryMinimumDwell}</span>
        </div>
      </label>
      <label>
        <div className="label-value-container">
          <span> assetExitMinimumDwell: </span>
          <span className="value">{selectedRule.assetExitMinimumDwell}</span>
        </div>
      </label>
      <label>
        <div className="label-value-container">
          <span> Creation Time:</span>
          <span className="value">
            {new Date(
              selectedRule.base.creationTime.epochTime,
            ).toLocaleString()}
          </span>
        </div>
      </label>
      <label>
        <div className="label-value-container">
          <span> Last Update Time:</span>
          <span className="value">
            {new Date(
              selectedRule.base.lastUpdateTime.epochTime,
            ).toLocaleDateString()}
          </span>
        </div>
      </label>
    </form>
  );
};

export default ProximityRuleDetails;
