import React, { useState, useMemo, useContext } from "react";
import { Link } from "react-router-dom";
import { Column, useTable, useSortBy } from "react-table";
import {
  Tenant,
  Account,
  Organization,
  User,
} from "pages/Provision/Provision.type";
import { FetchSitesContext } from "pages/Provision/Provision";
import Table from "pages/Provision/tables/Table";

import Modal from "react-modal";
import EditOrganizationForm from "pages/Provision/forms/EditOrganizationForm";

import { FaRegTrashCan } from "react-icons/fa6";

type SiteTableProps = {
  tenant: Tenant;
  account: Account;
  organizations: Organization[];
  users: User[];
};

type columnData = {
  customerName: string;
  accountName: string;
  orgResName: string;
  orgName: string;
  status: string;
  users: number;
  extraProperties: string;
  createTime: string;
  lastUpdateTime: string;
};

const OrganizationTable: React.FC<SiteTableProps> = ({
  tenant,
  account,
  organizations,
  users,
}) => {
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState<Organization>(null!);
  const [searchQuery, setSearchQuery] = useState("");
  const fetchSites = useContext(FetchSitesContext);

  const data = useMemo(
    () =>
      organizations
        .filter((org) => org.status !== "deleted")
        .map((org) => ({
          ...org,
          customerName: tenant.customerName,
          accountName: account.accountName,
          users: users.filter((user) => user.orgResName === org.orgResName)
            .length,
          createTime: new Date(org.creationTime.epochTime).toLocaleString(),
          lastUpdateTime: new Date(
            org.lastUpdateTime.epochTime,
          ).toLocaleString(),
          extraProperties: org.extraProperties
            ? org.extraProperties
                .map((prop) => `${prop.key}: ${prop.value}`)
                .join(",")
            : "",
        }))
        .filter((org) =>
          Object.values(org).some((value) =>
            String(value).toLowerCase().includes(searchQuery.toLowerCase()),
          ),
        ),
    [tenant, account, organizations, users, searchQuery],
  );

  const columns: Column<columnData>[] = React.useMemo(
    () => [
      {
        Header: "Account Name",
        accessor: "accountName",
      },
      {
        Header: "orgResName",
        accessor: "orgResName",
      },
      {
        Header: "Org Name",
        accessor: "orgName",
      },
      {
        Header: "Extra Properties",
        accessor: "extraProperties",
      },
      {
        Header: "Users",
        accessor: "users",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Create Time",
        accessor: "createTime",
      },
      {
        Header: "Last Update Time",
        accessor: "lastUpdateTime",
      },
      {
        Header: "Action",
        Cell: ({ row }: { row: any }) => (
          <div>
            <button
              disabled={
                row.original.status !== "inactive" || row.original.users > 0
              }
              onClick={() => {
                setSelectedOrg(
                  organizations.filter(
                    (org) => org.orgResName === row.original.orgResName,
                  )[0],
                );
                setDeleteModalIsOpen(true);
              }}
            >
              <FaRegTrashCan />
            </button>
          </div>
        ),
      },
    ],
    [tenant, account, organizations, users],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy);

  return (
    <div>
      <div>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/scrappy/provision">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to={`/scrappy/provision/${tenant.customerResName}`}>
                {tenant.customerName}
              </Link>
            </li>
            <li className="breadcrumb-item">{account.accountName}</li>
            <li className="breadcrumb-item active" aria-current="page">
              Firms
            </li>
          </ol>
        </nav>
      </div>

      <Modal
        isOpen={deleteModalIsOpen}
        onRequestClose={() => setDeleteModalIsOpen(false)}
        className="modal-content"
        ariaHideApp={false}
      >
        <EditOrganizationForm edit={false} selectedOrganization={selectedOrg} />
      </Modal>

      <div className="table-header-container">
        <input
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <button onClick={() => setSearchQuery("")}>Clear</button>
        <button className="button-end" onClick={fetchSites}>
          Refresh
        </button>
      </div>
      <Table
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        rows={rows}
        prepareRow={prepareRow}
      />
    </div>
  );
};

export default OrganizationTable;
