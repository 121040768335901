import { useState, useContext } from "react";
import { Organization } from "pages/Provision/Provision.type";
import "pages/Provision/Provision.css";
import { signRequest } from "utils/utils";
import { FetchSitesContext } from "pages/Provision/Provision";

interface OrganizationFormProps {
  edit: boolean;
  selectedOrganization: Organization;
}

const EditOrganizationForm: React.FC<OrganizationFormProps> = ({
  edit,
  selectedOrganization,
}) => {
  const [organization, setOrganization] = useState<Organization>({
    orgResName: selectedOrganization.orgResName,
    orgName: selectedOrganization.orgName,
    status: selectedOrganization.status,
    creationTime: { epochTime: selectedOrganization.creationTime.epochTime },
    lastUpdateTime: {
      epochTime: selectedOrganization.lastUpdateTime.epochTime,
    },
    customerResName: selectedOrganization.customerResName,
    accountResName: selectedOrganization.accountResName,
    extraProperties: selectedOrganization.extraProperties,
  });

  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const fetchSites = useContext(FetchSitesContext);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setOrganization({ ...organization, [name]: value });
  };

  const patchOrganization = async () => {
    setError("");
    setLoading(true);
    const url = `/accounts/${organization.accountResName}/organizations/${organization.orgResName}`;
    const method = "PATCH";
    const host = "USERS";

    const body = {
      orgName: organization.orgName,
      status: edit ? organization.status : "deleted",
    };

    try {
      const signedRequest = await signRequest(host, url, method, body);
      const response = await fetch(signedRequest.url, signedRequest);

      if (!response.ok) {
        setError("Failed to update organization");
        console.error("Failed to update organization", response.statusText);
      } else {
        const createdOrganization = await response.json();
        console.log("Updated organization", createdOrganization);

        createdOrganization.errorMessage
          ? setError("Failed to update organization")
          : fetchSites();
        setLoading(false);
      }
    } catch (error) {
      setError("Failed to update organization");
      console.error("Error:", error);
      setLoading(false);
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await patchOrganization();
  };

  const editForm = (
    <label>
      Name:
      <input
        type="text"
        name="orgName"
        value={organization.orgName}
        onChange={handleChange}
      />
    </label>
  );

  const deleteForm = (
    <label>
      Are you sure you want to delete {selectedOrganization.orgName}?
    </label>
  );

  return (
    <form onSubmit={handleSubmit}>
      <h2>{edit ? "Update" : "Delete"} Firm</h2>
      {loading && (
        <div className="loader-container">
          <div className="loader" />
        </div>
      )}
      {loading && <label className="post">Updating Firm...</label>}
      {error && <label className="error">{error}</label>}
      {edit ? editForm : deleteForm}
      <input type="submit" value={edit ? "Edit Firm" : "Delete Firm"} />
    </form>
  );
};

export default EditOrganizationForm;
