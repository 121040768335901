import { useState, useContext } from "react";
import {
  CategoryTree,
  CategoryPath,
  ProximityRule,
  DayOfWeek,
} from "pages/Provision/Provision.type";
import "pages/Provision/Provision.css";
import { signRequest } from "utils/utils";
import { FetchSiteByOperationsContext } from "pages/Provision/Provision";

interface FormErrors {
  ruleName?: string;
  ruleEventCategory?: string;
  ruleType?: string;
  ruleEventNotification?: string;
  periodStartDate?: string;
  periodEndDate?: string;
  operationalDaysOfWeek?: string;
  operatingDayStartTime?: string;
  operatingDayEndTime?: string;
  localTimezoneOffset?: string;
  assetCategoryA?: string;
  assetCategoryB?: string;
  redZoneProximityRadius?: string;
  yellowZoneProximityRadius?: string;
  greenZoneProximityRadius?: string;
  assetEntryMinimumDwell?: string;
  assetExitMinimumDwell?: string;
}

function convertEpochToDateString(epochTime: number) {
  const date = new Date(epochTime);
  const year = date.getFullYear().toString();
  const month = ("0" + (date.getMonth() + 1)).slice(-2); // Ensure two digits
  const day = ("0" + date.getDate()).slice(-2); // Ensure two digits
  return `${year}-${month}-${day}`;
}

const ProximityRuleForm: React.FC<{
  account: string;
  site: string;
  trees: CategoryTree[];
  pathes: CategoryPath[];
}> = ({ account, site, /* trees,*/ pathes }) => {
  const [proximityRule, setProximityRule] = useState<ProximityRule>({
    accountResName: account,
    siteResName: site,
    base: {
      ruleResName: "",
      ruleName: "",
      ruleEventCategory: "",
      status: "active",
      creationTime: { epochTime: 0 },
      lastUpdateTime: { epochTime: 0 },
      enableRuleEventNotification: false,
      operationalPeriod: {
        periodStartDate: { epochTime: new Date().setHours(0, 0, 0, 0) },
        periodEndDate: { epochTime: new Date().setHours(0, 0, 0, 0) },
        operatingDaysOfWeek: ["Mon", "Tue", "Wed", "Thu", "Fri"],
        operatingDayStartTime: "00:00",
        operatingDayEndTime: "00:00",
        localTimezoneOffset: 0,
      },
    },
    ruleType: "asset-proximity-rule",
    assetCategoryA: {
      categoryTreeResName: "",
      categoryPathResName: "",
    },
    assetCategoryB: {
      categoryTreeResName: "",
      categoryPathResName: "",
    },
    redZoneProximityRadius: 0,
    yellowZoneProximityRadius: 0,
    greenZoneProximityRadius: 0,
    assetEntryMinimumDwell: 0,
    assetExitMinimumDwell: 0,
  });

  const [error, setError] = useState<string>("");
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [loading, setLoading] = useState<boolean>(false);
  const fetchSites = useContext(FetchSiteByOperationsContext);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError("");
    const { name, value } = event.target;
    const keys = name.split(".");

    if (keys.length === 1) {
      if (name.match(/zone|dwell/i)) {
        setProximityRule({ ...proximityRule, [name]: parseInt(value, 10) });
      } else {
        setProximityRule({ ...proximityRule, [name]: value });
      }
    }

    if (keys.length === 2) {
      const [parentKey, childKey] = keys;
      if (parentKey === "base") {
        setProximityRule({
          ...proximityRule,
          base: { ...proximityRule.base, [childKey]: value },
        });
      }
    }

    if (keys.length === 3) {
      const [parentKey, childKey, grandChildKey] = keys;
      if (
        parentKey === "base" &&
        childKey === "operationalPeriod" &&
        (grandChildKey === "periodStartDate" ||
          grandChildKey === "periodEndDate")
      ) {
        const dateParts = value.split("-");
        const year = parseInt(dateParts[0], 10); // Directly parse the full year
        const month = parseInt(dateParts[1], 10) - 1; // Month is 0-indexed in JavaScript Date
        const day = parseInt(dateParts[2], 10);

        const dateInMilliseconds = new Date(year, month, day).getTime();

        setProximityRule({
          ...proximityRule,
          base: {
            ...proximityRule.base,
            operationalPeriod: {
              ...proximityRule.base.operationalPeriod,
              [grandChildKey]: { epochTime: dateInMilliseconds },
            },
          },
        });
      }
    }
  };

  const activateProxitmityRule = async (id: string) => {
    const url = `/accounts/${account}/sites/${site}/assets/rules/proximity-rules/${id}`;
    const method = "PATCH";
    const host = "ASSETS";

    const body = {
      base: {
        ruleName: proximityRule.base.ruleName,
        status: "active",
        enableRuleEventNotification:
          proximityRule.base.enableRuleEventNotification,
        operationalPeriod: {
          periodStartDate: {
            epochTime:
              proximityRule.base.operationalPeriod.periodStartDate.epochTime,
          },
          periodEndDate: {
            epochTime:
              proximityRule.base.operationalPeriod.periodEndDate.epochTime,
          },
          operatingDaysOfWeek:
            proximityRule.base.operationalPeriod.operatingDaysOfWeek,
          operatingDayStartTime:
            proximityRule.base.operationalPeriod.operatingDayStartTime,
          operatingDayEndTime:
            proximityRule.base.operationalPeriod.operatingDayEndTime,
          localTimezoneOffset:
            proximityRule.base.operationalPeriod.localTimezoneOffset,
        },
        ruleEventCategory: proximityRule.base.ruleEventCategory,
      },
      assetCategoryA: {
        categoryTreeResName: proximityRule.assetCategoryA.categoryTreeResName,
        categoryPathResName: proximityRule.assetCategoryA.categoryPathResName,
      },
      assetCategoryB: {
        categoryTreeResName: proximityRule.assetCategoryB.categoryTreeResName,
        categoryPathResName: proximityRule.assetCategoryB.categoryPathResName,
      },
      redZoneProximityRadius: proximityRule.redZoneProximityRadius,
      yellowZoneProximityRadius: proximityRule.yellowZoneProximityRadius,
      greenZoneProximityRadius: proximityRule.greenZoneProximityRadius,
      assetEntryMinimumDwell: proximityRule.assetEntryMinimumDwell,
      assetExitMinimumDwell: proximityRule.assetExitMinimumDwell,
    };

    const signedPatchRequest = await signRequest(host, url, method, body);
    const patchResponse = await fetch(
      signedPatchRequest.url,
      signedPatchRequest,
    );

    if (!patchResponse.ok) {
      console.error("Failed to update proximity rule", patchResponse);
      return false;
    } else {
      const updatedProximityRule = await patchResponse.json();
      console.log("Updated proximity rule", updatedProximityRule);

      if (updatedProximityRule.errorMessage) {
        setError("Failed to activate proximity rule");
        return false;
      } else {
        console.log("Proximity rule updated successfully");
        return true;
      }
    }
  };

  const postProximityRule = async () => {
    setError("");
    setLoading(true);

    const url = `/accounts/${account}/sites/${site}/assets/rules/proximity-rules`;
    const method = "POST";
    const host = "ASSETS";

    const body = {
      base: {
        ruleName: proximityRule.base.ruleName,
        enableRuleEventNotification:
          proximityRule.base.enableRuleEventNotification,
        operationalPeriod: {
          periodStartDate: {
            epochTime:
              proximityRule.base.operationalPeriod.periodStartDate.epochTime,
          },
          periodEndDate: {
            epochTime:
              proximityRule.base.operationalPeriod.periodEndDate.epochTime,
          },
          operatingDaysOfWeek:
            proximityRule.base.operationalPeriod.operatingDaysOfWeek,
          operatingDayStartTime:
            proximityRule.base.operationalPeriod.operatingDayStartTime,
          operatingDayEndTime:
            proximityRule.base.operationalPeriod.operatingDayEndTime,
          localTimezoneOffset:
            proximityRule.base.operationalPeriod.localTimezoneOffset,
        },
        ruleEventCategory: proximityRule.base.ruleEventCategory,
      },
      ruleType: proximityRule.ruleType,
      assetCategoryA: {
        categoryTreeResName: proximityRule.assetCategoryA.categoryTreeResName,
        categoryPathResName: proximityRule.assetCategoryA.categoryPathResName,
      },
      assetCategoryB: {
        categoryTreeResName: proximityRule.assetCategoryB.categoryTreeResName,
        categoryPathResName: proximityRule.assetCategoryB.categoryPathResName,
      },
      redZoneProximityRadius: proximityRule.redZoneProximityRadius,
      yellowZoneProximityRadius: proximityRule.yellowZoneProximityRadius,
      greenZoneProximityRadius: proximityRule.greenZoneProximityRadius,
      assetEntryMinimumDwell: proximityRule.assetEntryMinimumDwell,
      assetExitMinimumDwell: proximityRule.assetExitMinimumDwell,
    };

    try {
      const signedRequest = await signRequest(host, url, method, body);
      const response = await fetch(signedRequest.url, signedRequest);

      if (!response.ok) {
        setError("Failed to create proximity rule");
        console.error("Failed to create proximity rule", response);
      } else {
        const createdProximityRule = await response.json();
        console.log("Created proximity rule", createdProximityRule);

        if (createdProximityRule.errorMessage) {
          setError("Failed to create proximity rule");
        } else {
          const activated = await activateProxitmityRule(createdProximityRule);
          activated && fetchSites(account, site, ["proximity-rules"]);
        }

        setLoading(false);
      }
    } catch (error) {
      setError("Failed to create proximity rule");
      console.error("Error:", error);
      setLoading(false);
    }
  };

  const validateForm = () => {
    const errors: FormErrors = {};

    if (!proximityRule.base.ruleName) {
      errors.ruleName = "Rule Name is required";
    }

    if (!proximityRule.base.ruleEventCategory) {
      errors.ruleEventCategory = "Rule Event Category is required";
    }

    if (!proximityRule.ruleType) {
      errors.ruleType = "Rule Type is required";
    }

    if (!proximityRule.base.operationalPeriod.periodStartDate.epochTime) {
      errors.periodStartDate = "Period Start Date is required";
    }

    if (!proximityRule.base.operationalPeriod.periodEndDate.epochTime) {
      errors.periodEndDate = "Period End Date is required";
    }

    if (!proximityRule.base.operationalPeriod.operatingDaysOfWeek.length) {
      errors.operationalDaysOfWeek = "Operating Days of Week is required";
    }

    if (!proximityRule.base.operationalPeriod.operatingDayStartTime) {
      errors.operatingDayStartTime = "Operating Day Start Time is required";
    }

    if (!proximityRule.base.operationalPeriod.operatingDayEndTime) {
      errors.operatingDayEndTime = "Operating Day End Time is required";
    }

    if (
      !proximityRule.assetCategoryA.categoryTreeResName ||
      !proximityRule.assetCategoryA.categoryPathResName
    ) {
      errors.assetCategoryA = "Asset Category A is required";
    }

    if (
      !proximityRule.assetCategoryB.categoryTreeResName ||
      !proximityRule.assetCategoryB.categoryPathResName
    ) {
      errors.assetCategoryB = "Asset Category B is required";
    }

    if (!proximityRule.redZoneProximityRadius) {
      errors.redZoneProximityRadius = "Red Zone Proximity Radius is required";
    }

    if (!proximityRule.yellowZoneProximityRadius) {
      errors.yellowZoneProximityRadius =
        "Yellow Zone Proximity Radius is required";
    }

    if (!proximityRule.greenZoneProximityRadius) {
      errors.greenZoneProximityRadius =
        "Green Zone Proximity Radius is required";
    }

    if (!proximityRule.assetEntryMinimumDwell) {
      errors.assetEntryMinimumDwell = "Asset Entry Minimum Dwell is required";
    }

    if (!proximityRule.assetExitMinimumDwell) {
      errors.assetExitMinimumDwell = "Asset Exit Minimum Dwell is required";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!validateForm()) {
      return;
    }

    await postProximityRule();
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>Add Proximity Rule</h2>
      {loading && (
        <div className="loader-container">
          <div className="loader" />
        </div>
      )}
      {loading && <label className="post">Adding Proximity Rule...</label>}
      {error && <label className="error">{error}</label>}
      <label>
        Rule Name:
        <input
          type="text"
          name="base.ruleName"
          value={proximityRule.base.ruleName}
          onChange={handleChange}
        />
        <label className="error">{formErrors.ruleName}</label>
      </label>
      <label>
        Rule Event Category:
        <input
          type="text"
          name="base.ruleEventCategory"
          value={proximityRule.base.ruleEventCategory}
          onChange={handleChange}
        />
        <label className="error">{formErrors.ruleEventCategory}</label>
      </label>
      <label>
        Rule Type:
        <input
          type="text"
          name="ruleType"
          value={proximityRule.ruleType}
          onChange={handleChange}
          disabled={true}
        />
        <label className="error">{formErrors.ruleType}</label>
      </label>
      <label>
        Enable Rule Event Notification:
        <input
          type="checkbox"
          name="base.enableRuleEventNotification"
          checked={proximityRule.base.enableRuleEventNotification}
          onChange={(event) =>
            setProximityRule({
              ...proximityRule,
              base: {
                ...proximityRule.base,
                enableRuleEventNotification: event.target.checked,
              },
            })
          }
        />
      </label>
      <label>
        Period Start Date:
        <input
          type="date"
          name="base.operationalPeriod.periodStartDate"
          value={convertEpochToDateString(
            proximityRule.base.operationalPeriod.periodStartDate.epochTime,
          )}
          onChange={handleChange}
        />
        <label className="error">{formErrors.periodStartDate}</label>
      </label>
      <label>
        Period End Date:
        <input
          type="date"
          name="base.operationalPeriod.periodEndDate"
          value={convertEpochToDateString(
            proximityRule.base.operationalPeriod.periodEndDate.epochTime,
          )}
          onChange={handleChange}
        />
        <label className="error">{formErrors.periodEndDate}</label>
      </label>
      <label>
        Select Operating Days:
        <select
          multiple
          value={proximityRule.base.operationalPeriod.operatingDaysOfWeek}
          onChange={(event) => {
            setProximityRule({
              ...proximityRule,
              base: {
                ...proximityRule.base,
                operationalPeriod: {
                  ...proximityRule.base.operationalPeriod,
                  operatingDaysOfWeek: Array.from(
                    event.target.selectedOptions,
                    (option) => option.value as DayOfWeek,
                  ),
                },
              },
            });
          }}
        >
          <option value="Mon">Mon</option>
          <option value="Tue">Tue</option>
          <option value="Wed">Wed</option>
          <option value="Thu">Thu</option>
          <option value="Fri">Fri</option>
          <option value="Sat">Sat</option>
          <option value="Sun">Sun</option>
        </select>
        <label className="error">{formErrors.operationalDaysOfWeek}</label>
      </label>
      <label>
        Operating Day Start Time (hh:mm):
        <input
          type="text"
          name="base.operationalPeriod.operatingDayStartTime"
          value={proximityRule.base.operationalPeriod.operatingDayStartTime}
          onChange={(event) => {
            const value = event.target.value;
            setProximityRule({
              ...proximityRule,
              base: {
                ...proximityRule.base,
                operationalPeriod: {
                  ...proximityRule.base.operationalPeriod,
                  operatingDayStartTime: value,
                },
              },
            });
          }}
        />
        <label className="error">{formErrors.operatingDayStartTime}</label>
      </label>
      <label>
        Operating Day End Time (hh:mm):
        <input
          type="text"
          name="base.operationalPeriod.operatingDayEndTime"
          value={proximityRule.base.operationalPeriod.operatingDayEndTime}
          onChange={(event) => {
            const value = event.target.value;
            setProximityRule({
              ...proximityRule,
              base: {
                ...proximityRule.base,
                operationalPeriod: {
                  ...proximityRule.base.operationalPeriod,
                  operatingDayEndTime: value,
                },
              },
            });
          }}
        />
      </label>
      <label>
        Local Timezone Offset:
        <input
          type="number"
          name="base.operationalPeriod.localTimezoneOffset"
          value={proximityRule.base.operationalPeriod.localTimezoneOffset}
          onChange={(event) => {
            const value = parseInt(event.target.value, 10);
            setProximityRule({
              ...proximityRule,
              base: {
                ...proximityRule.base,
                operationalPeriod: {
                  ...proximityRule.base.operationalPeriod,
                  localTimezoneOffset: value,
                },
              },
            });
          }}
        />
        <label className="error">{formErrors.localTimezoneOffset}</label>
      </label>

      <label>
        Asset Category A:
        <select
          name="assetCategoryA"
          value={`${proximityRule.assetCategoryA.categoryTreeResName}/${proximityRule.assetCategoryA.categoryPathResName}`}
          onChange={(event) => {
            const [categoryTreeResName, categoryPathResName] =
              event.target.value.split("/");
            setProximityRule({
              ...proximityRule,
              assetCategoryA: {
                categoryTreeResName: categoryTreeResName,
                categoryPathResName: categoryPathResName,
              },
            });
          }}
        >
          <option value="">Select cTree and cPath</option>
          {pathes.map((path) => (
            <option
              key={path.categoryPathResName}
              value={`${path.categoryTreeResName}/${path.categoryPathResName}`}
            >
              {path.categoryPath}
            </option>
          ))}
        </select>
        <label className="error">{formErrors.assetCategoryA}</label>
      </label>

      <label>
        Asset Category B:
        <select
          name="assetCategoryB"
          value={`${proximityRule.assetCategoryB.categoryTreeResName}/${proximityRule.assetCategoryB.categoryPathResName}`}
          onChange={(event) => {
            const [categoryTreeResName, categoryPathResName] =
              event.target.value.split("/");
            setProximityRule({
              ...proximityRule,
              assetCategoryB: {
                categoryTreeResName: categoryTreeResName,
                categoryPathResName: categoryPathResName,
              },
            });
          }}
        >
          <option value="">Select cTree and cPath</option>
          {pathes.map((path) => (
            <option
              key={path.categoryPathResName}
              value={`${path.categoryTreeResName}/${path.categoryPathResName}`}
            >
              {path.categoryPath}
            </option>
          ))}
        </select>
        <label className="error">{formErrors.assetCategoryB}</label>
      </label>

      <label>
        Red Zone Proximity Radius:
        <input
          type="number"
          name="redZoneProximityRadius"
          value={proximityRule.redZoneProximityRadius}
          onChange={handleChange}
        />
        <label className="error">{formErrors.redZoneProximityRadius}</label>
      </label>

      <label>
        Yellow Zone Proximity Radius:
        <input
          type="number"
          name="yellowZoneProximityRadius"
          value={proximityRule.yellowZoneProximityRadius}
          onChange={handleChange}
        />
        <label className="error">{formErrors.yellowZoneProximityRadius}</label>
      </label>

      <label>
        Green Zone Proximity Radius:
        <input
          type="number"
          name="greenZoneProximityRadius"
          value={proximityRule.greenZoneProximityRadius}
          onChange={handleChange}
        />
        <label className="error">{formErrors.greenZoneProximityRadius}</label>
      </label>

      <label>
        Asset Entry Minimum Dwell:
        <input
          type="number"
          name="assetEntryMinimumDwell"
          value={proximityRule.assetEntryMinimumDwell}
          onChange={handleChange}
        />
        <label className="error">{formErrors.assetEntryMinimumDwell}</label>
      </label>

      <label>
        Asset Exit Minimum Dwell:
        <input
          type="number"
          name="assetExitMinimumDwell"
          value={proximityRule.assetExitMinimumDwell}
          onChange={handleChange}
        />
        <label className="error">{formErrors.assetExitMinimumDwell}</label>
      </label>

      <input type="submit" value="Add Proximity Rule" />
    </form>
  );
};

export default ProximityRuleForm;
