import { useState, useContext } from "react";
import { Floor } from "pages/Provision/Provision.type";
import "pages/Provision/Provision.css";
import { signRequest } from "utils/utils";
import { FetchSiteByOperationsContext } from "pages/Provision/Provision";

interface FormErrors {
  floorName?: string;
  floorIndex?: string;
  ceilingHeight?: string;
  readerPlacementLevel?: string;
}

const FloorForm: React.FC<{ account: string; site: string }> = ({
  account,
  site,
}) => {
  const [floor, setFloor] = useState<Floor>({
    customerResName: "",
    accountResName: account,
    siteResName: site,
    floorResName: "",
    floorName: "",
    floorIndex: 1,
    ceilingHeight: 3000,
    readerPlacementLevel: 0,
    creationTime: { epochTime: 0 },
    lastUpdateTime: { epochTime: 0 },
  });
  const [error, setError] = useState<string>("");
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [loading, setLoading] = useState<boolean>(false);
  const fetchSites = useContext(FetchSiteByOperationsContext);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError("");
    const { name, value } = event.target;
    setFloor({ ...floor, [name]: value });
  };

  const postFloor = async () => {
    setError("");
    setLoading(true);

    const url = `/accounts/${account}/sites/${site}/floors`;
    const method = "POST";
    const host = "SITES";

    const body = {
      floorIndex: Math.round(floor.floorIndex),
      floorName: floor.floorName,
      readerPlacementLevel: Math.round(floor.readerPlacementLevel),
      ceilingHeight: Math.round(floor.ceilingHeight),
    };

    try {
      const signedRequest = await signRequest(host, url, method, body);
      const response = await fetch(signedRequest.url, signedRequest);

      if (!response.ok) {
        setError("Failed to create floor");
        console.error("Failed to create floor", response);
      } else {
        const createdFloor = await response.json();
        console.log("Created floor", createdFloor);

        createdFloor.errorMessage
          ? setError("Failed to create floor")
          : fetchSites(account, site, ["floors"]);
        setLoading(false);
      }
    } catch (error) {
      setError("Failed to create floor");
      console.error("Error:", error);
      setLoading(false);
    }
  };

  const validateForm = () => {
    const errors: FormErrors = {};

    if (!floor.floorName) {
      errors.floorName = "Floor name is required";
    }

    if (!floor.floorIndex) {
      errors.floorIndex = "Floor index is required";
    }

    if (!floor.ceilingHeight) {
      errors.ceilingHeight = "Ceiling height is required";
    }

    if (
      floor.readerPlacementLevel === undefined ||
      floor.readerPlacementLevel === null
    ) {
      errors.readerPlacementLevel = "Reader placement level is required";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!validateForm()) {
      return;
    }
    await postFloor();
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>Add Floor</h2>
      {loading && (
        <div className="loader-container">
          <div className="loader" />
        </div>
      )}
      {loading && <label className="post">Adding Floor...</label>}
      {error && <label className="error">{error}</label>}
      <label>
        Name:
        <input
          type="text"
          name="floorName"
          value={floor.floorName}
          onChange={handleChange}
        />
      </label>
      {formErrors.floorName && (
        <label className="form-error">{formErrors.floorName}</label>
      )}
      <label>
        Index:
        <input
          type="number"
          name="floorIndex"
          value={floor.floorIndex}
          onChange={handleChange}
        />
      </label>
      {formErrors.floorIndex && (
        <label className="form-error">{formErrors.floorIndex}</label>
      )}
      <label>
        Reader Placement Level (cm):
        <input
          type="number"
          name="readerPlacementLevel"
          value={floor.readerPlacementLevel}
          onChange={handleChange}
        />
      </label>
      {formErrors.readerPlacementLevel && (
        <label className="form-error">{formErrors.readerPlacementLevel}</label>
      )}
      <label>
        Ceiling Height (cm):
        <input
          type="number"
          name="ceilingHeight"
          value={floor.ceilingHeight}
          onChange={handleChange}
        />
      </label>
      {formErrors.ceilingHeight && (
        <label className="form-error">{formErrors.ceilingHeight}</label>
      )}
      <input type="submit" value="Add Floor" />
    </form>
  );
};

export default FloorForm;
