import { useAuth } from "../AuthContext";
import { Navigate } from "react-router-dom";

const PrivateRoute: React.FC<{ element: React.ReactElement }> = ({
  element,
}) => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? element : <Navigate to="/login" replace />;
};

export default PrivateRoute;
