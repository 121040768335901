import { useState } from "react";
import { Map } from "pages/Provision/Provision.type";
import "pages/Provision/Provision.css";
import { signRequest } from "utils/utils";

const DownloadMapForm: React.FC<{ selectedMap: Map }> = ({ selectedMap }) => {
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [mapUrl, setMapUrl] = useState<string>("");

  const fetchMapUrl = async () => {
    setError("");
    setLoading(true);
    const url = `/accounts/${selectedMap.accountResName}/sites/${selectedMap.siteResName}/maps/${selectedMap.mapResName}/data/download/pre-signed-url?mapFormat=zip&deployment=alpha`;
    const method = "GET";
    const host = "MAPS";

    try {
      const signedRequest = await signRequest(host, url, method);
      const response = await fetch(signedRequest.url, signedRequest);

      if (!response.ok) {
        setError("Failed to fetch map url");
        console.error("Failed to fetch map url", response);
      } else {
        const mapUrl = await response.json();
        console.log("Map URL", mapUrl);
        setMapUrl(mapUrl);
        setLoading(false);
      }
    } catch (error) {
      setError("Failed to fetch map url");
      console.error("Error:", error);
      setLoading(false);
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await fetchMapUrl();
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>Download Map</h2>
      {loading && (
        <div className="loader-container">
          <div className="loader" />
        </div>
      )}
      {loading && <label className="post">Generate Map URL ...</label>}
      <label className="error">{error}</label>
      {mapUrl ? (
        <>
          <label>Map URL:</label>
          <div className="map-url">
            <a href={mapUrl} target="_blank" rel="noopener noreferrer">
              {mapUrl}
            </a>
          </div>
        </>
      ) : (
        <input type="submit" value="Generate Map URL" />
      )}
    </form>
  );
};

export default DownloadMapForm;
