import { useState, useContext } from "react";
import { Asset, Tag } from "pages/Provision/Provision.type";
import "pages/Provision/Provision.css";
import { signRequest } from "utils/utils";
import { FetchSiteByOperationsContext } from "pages/Provision/Provision";

interface SiteFormProps {
  selectedAsset: Asset;
  tags: Tag[];
}

const AttachmentForm: React.FC<SiteFormProps> = ({ selectedAsset, tags }) => {
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [tag, setTag] = useState<Tag>({
    accountResName: "",
    siteResName: "",
    tag: {
      tagResName: "",
      tagName: "",
    },
    asset: {
      assetResName: "",
      assetName: "",
      assetCategory: {
        categoryTreeResName: "",
        categoryPathResName: "",
      },
    },
  });
  const fetchSites = useContext(FetchSiteByOperationsContext);

  const putAssetAttachedTags = async () => {
    setError("");
    setLoading(true);

    const url = `/accounts/${selectedAsset.accountResName}/sites/${selectedAsset.siteResName}/assets/${selectedAsset.assetResName}/attached-tags`;
    const method = "PUT";
    const host = "ASSETS";
    const body = [
      {
        tagName: tag.tag.tagName,
        tagResName: tag.tag.tagResName,
      },
    ];

    try {
      const signedRequest = await signRequest(host, url, method, body);
      const response = await fetch(signedRequest.url, signedRequest);

      if (!response.ok) {
        setError("Failed to update asset");
        console.error("Failed to update asset", response);
      } else {
        const createdAsset = await response.json();
        console.log("Updated asset", createdAsset);

        createdAsset.errorMessage
          ? setError("Failed to update asset")
          : fetchSites(
              selectedAsset.accountResName,
              selectedAsset.siteResName,
              ["assets", "tags"],
            );

        setLoading(false);
      }
    } catch (error) {
      setError("Failed to update asset");
      console.error("Error:", error);
      setLoading(false);
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await putAssetAttachedTags();
  };

  const attachForm = (
    <>
      <label>
        Select Tag:
        <select
          name="tag"
          value={`${tag.tag.tagResName}/${tag.tag.tagName}`}
          onChange={(event) => {
            const [tagResName, tagName] = event.target.value.split("/");
            setTag({
              ...tag,
              tag: {
                tagResName: tagResName,
                tagName: tagName,
              },
            });
          }}
        >
          <option value="">Select tag</option>
          {tags
            .filter((tag) => !tag.asset)
            .sort((a, b) => a.tag.tagName.localeCompare(b.tag.tagName))
            .map((tag) => (
              <option
                key={tag.tag.tagResName}
                value={`${tag.tag.tagResName}/${tag.tag.tagName}`}
              >
                {tag.tag.tagName}
              </option>
            ))}
        </select>
      </label>
    </>
  );

  return (
    <form onSubmit={handleSubmit}>
      <h2>Attach Tag to {selectedAsset.assetName}</h2>
      {loading && (
        <div className="loader-container">
          <div className="loader" />
        </div>
      )}
      {loading && <label className="post">Attaching tag ...</label>}
      {error && <label className="error">{error}</label>}
      {attachForm}
      <input type="submit" value={"Attach Tag"} />
    </form>
  );
};

export default AttachmentForm;
