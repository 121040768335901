import { useState, useContext } from "react";
import { CategoryPath } from "pages/Provision/Provision.type";
import "pages/Provision/Provision.css";
import { signRequest } from "utils/utils";
import { FetchSiteByOperationsContext } from "pages/Provision/Provision";

interface FormErrors {
  categoryPath?: string;
}

const CPathForm: React.FC<{ account: string; site: string; tree: string }> = ({
  account,
  site,
  tree,
}) => {
  const [path, setPath] = useState<CategoryPath>({
    accountResName: account,
    siteResName: site,
    categoryTreeResName: "",
    categoryPathResName: "",
    categoryPath: "",
    categoryStatus: "active",
  });
  const [error, setError] = useState<string>("");
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [loading, setLoading] = useState<boolean>(false);
  const fetchSites = useContext(FetchSiteByOperationsContext);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setPath({ ...path, [name]: value });
  };

  const postPath = async () => {
    setError("");
    setLoading(true);

    const url = `/accounts/${account}/sites/${site}/assets/category-trees/${tree}/categories`;
    const method = "POST";
    const host = "ASSETS";

    const body = {
      categoryPath: path.categoryPath,
    };

    try {
      const signedRequest = await signRequest(host, url, method, body);
      const response = await fetch(signedRequest.url, signedRequest);

      if (!response.ok) {
        setError("Failed to create path");
        console.error("Failed to create path", response);
      } else {
        const createdPath = await response.json();
        console.log("Created path", createdPath);

        if (createdPath.errorMessage) {
          setError("Failed to create path");
        } else {
          const success = await activatePath(createdPath, path.categoryPath);
          success && fetchSites(account, site, ["category-trees"]);
        }

        setLoading(false);
      }
    } catch (error) {
      setError("Failed to create path");
      console.error("Error:", error);
      setLoading(false);
    }
  };

  const activatePath = async (id: string, name: string) => {
    setError("");
    setLoading(true);

    const url = `/accounts/${account}/sites/${site}/assets/category-trees/${tree}/categories/${id}`;
    const method = "PATCH";
    const host = "ASSETS";

    const body = {
      categoryPath: name,
      categoryStatus: "active",
    };

    try {
      const signedRequest = await signRequest(host, url, method, body);
      const response = await fetch(signedRequest.url, signedRequest);

      if (!response.ok) {
        setError("Failed to activate path");
        console.error("Failed to activate path", response);
        return false;
      } else {
        const updatedPath = await response.json();

        if (updatedPath.errorMessage) {
          setError("Failed to activate path");
          return false;
        } else {
          console.log("Path activated successfully");
          return true;
        }
      }
    } catch (error) {
      setError("Failed to create path");
      console.error("Error:", error);
      setLoading(false);
    }
  };

  const validateForm = () => {
    const errors: FormErrors = {};

    if (!path.categoryPath) {
      errors.categoryPath = "Path name is required";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!validateForm()) {
      return;
    }
    await postPath();
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>Add Path </h2>
      {loading && (
        <div className="loader-container">
          <div className="loader" />
        </div>
      )}
      {loading && <label className="post">Adding Path...</label>}
      <label className="error">{error}</label>
      <label>
        Name:
        <input
          type="text"
          name="categoryPath"
          value={path.categoryPath}
          onChange={handleChange}
        />
      </label>
      {formErrors.categoryPath && (
        <label className="form-error">{formErrors.categoryPath}</label>
      )}
      <input type="submit" value="Add Path" />
    </form>
  );
};

export default CPathForm;
