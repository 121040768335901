import { Site, Organization, User } from "pages/Provision/Provision.type";
import "pages/Provision/Provision.css";

interface UserDetailsProps {
  tenant: string;
  account: string;
  sites: Site[];
  organizations: Organization[];
  selectedUser: User;
}

const UserDetails: React.FC<UserDetailsProps> = ({
  tenant,
  account,
  sites,
  organizations,
  selectedUser,
}) => {
  return (
    <form>
      <h2>User Details</h2>
      <label>
        <div className="label-value-container">
          <span>Customer:</span>
          <span className="value">{tenant}</span>
        </div>
      </label>
      <label>
        <div className="label-value-container">
          <span>Account:</span>
          <span className="value">{account}</span>
        </div>
      </label>
      <label>
        <div className="label-value-container">
          <span>UserResName:</span>
          <span className="value">{selectedUser.userResName}</span>
        </div>
      </label>
      <label>
        <div className="label-value-container">
          <span>First Name:</span>
          <span className="value">{selectedUser.firstName}</span>
        </div>
      </label>
      <label>
        <div className="label-value-container">
          <span>Last Name:</span>
          <span className="value">{selectedUser.lastName}</span>
        </div>
      </label>
      <label>
        <div className="label-value-container">
          <span>Email Address:</span>
          <span className="value">{selectedUser.emailAddress}</span>
        </div>
      </label>
      <label>
        <div className="label-value-container">
          <span>Phone Number:</span>
          <span className="value">{selectedUser.phoneNumber}</span>
        </div>
      </label>
      <label>
        <div className="label-value-container">
          <span>OrgResName:</span>
          <span className="value">{selectedUser.orgResName}</span>
        </div>
      </label>
      <label>
        <div className="label-value-container">
          <span>Org Name:</span>
          <span className="value">
            {
              organizations.filter(
                (org) => org.orgResName === selectedUser.orgResName,
              )[0]?.orgName
            }
          </span>
        </div>
      </label>
      <label>
        <div className="label-value-container">
          <span>Role:</span>
          <span className="value">
            {selectedUser.extraProperties
              ?.filter((prop) => prop.key === "role")
              .map((prop) => (
                <div key={prop.key}>
                  <span>{prop.value}</span>
                </div>
              ))}
          </span>
        </div>
      </label>
      <label>
        <div className="label-value-container">
          <span>Allowed Site(s):</span>
          <span className="value">
            {selectedUser.extraProperties
              ?.filter((prop) => prop.key === "allowed_site")
              .map((prop) => (
                <div key={prop.key}>
                  <span>
                    {prop.value
                      .replace(/[\[\]"]/g, "")
                      .split(",")
                      .map((site) => {
                        return sites.filter((s) => s.siteResName === site)[0]
                          ?.siteName;
                      })
                      .join(", ")}
                  </span>
                </div>
              ))}
          </span>
        </div>
      </label>
      <label>
        <div className="label-value-container">
          <span>Restricted Site(s):</span>
          <span className="value">
            {selectedUser.extraProperties
              ?.filter((prop) => prop.key === "restricted_site")
              .map((prop) => (
                <div key={prop.key}>
                  <span>
                    {prop.value
                      .replace(/[\[\]"]/g, "")
                      .split(",")
                      .map((site) => {
                        return sites.filter((s) => s.siteResName === site)[0]
                          ?.siteName;
                      })
                      .join(", ")}
                  </span>
                </div>
              ))}
          </span>
        </div>
      </label>
      <label>
        <div className="label-value-container">
          <span>Status:</span>
          <span className="value">{selectedUser.status}</span>
        </div>
      </label>
      <label>
        <div className="label-value-container">
          <span>Create Time:</span>
          <span className="value">
            {new Date(selectedUser.creationTime.epochTime).toLocaleString()}
          </span>
        </div>
      </label>
      <label>
        <div className="label-value-container">
          <span>Last Update Time:</span>
          <span className="value">
            {new Date(selectedUser.lastUpdateTime.epochTime).toLocaleString()}
          </span>
        </div>
      </label>
    </form>
  );
};

export default UserDetails;
