import { useState, useContext } from "react";
import { CategoryTree } from "pages/Provision/Provision.type";
import "pages/Provision/Provision.css";
import { signRequest } from "utils/utils";
import { FetchSiteByOperationsContext } from "pages/Provision/Provision";

interface FormErrors {
  categoryTreeName?: string;
}

const CTreeForm: React.FC<{ account: string; site: string }> = ({
  account,
  site,
}) => {
  const [tree, setTree] = useState<CategoryTree>({
    accountResName: account,
    siteResName: site,
    categoryTreeName: "",
    categoryTreeResName: "",
    categoryTreeStatus: "",
    categoryPathList: [],
  });
  const [error, setError] = useState<string>("");
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [loading, setLoading] = useState<boolean>(false);
  const fetchSites = useContext(FetchSiteByOperationsContext);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setTree({ ...tree, [name]: value });
  };

  const postTree = async () => {
    setError("");
    setLoading(true);
    const url = `/accounts/${account}/sites/${site}/assets/category-trees`;
    const method = "POST";
    const host = "ASSETS";

    const body = {
      categoryTreeName: tree.categoryTreeName,
    };

    try {
      const signedRequest = await signRequest(host, url, method, body);
      const response = await fetch(signedRequest.url, signedRequest);

      if (!response.ok) {
        setError("Failed to create tree");
        console.error("Failed to create tree", response);
      } else {
        const createdTree = await response.json();
        console.log("Created tree", createdTree);

        if (createdTree.errorMessage) {
          setError("Failed to create tree");
        } else {
          const success = await activateTree(
            createdTree,
            tree.categoryTreeName,
          );
          success && fetchSites(account, site, ["category-trees"]);
        }
        setLoading(false);
      }
    } catch (error) {
      setError("Failed to create tree");
      console.error("Error:", error);
      setLoading(false);
    }
  };

  const activateTree = async (id: string, name: string) => {
    const url = `/accounts/${account}/sites/${site}/assets/category-trees/${id}`;
    const method = "PATCH";
    const host = "ASSETS";

    const body = {
      categoryTreeName: name,
      categoryTreeStatus: "active",
    };

    const signedPatchRequest = await signRequest(host, url, method, body);
    const patchResponse = await fetch(
      signedPatchRequest.url,
      signedPatchRequest,
    );

    if (!patchResponse.ok) {
      setError("Failed to create tree");
      console.error("Failed to update tree", patchResponse);
      return false;
    } else {
      const updatedTree = await patchResponse.json();

      if (updatedTree.errorMessage) {
        setError("Failed to activate tree");
        return false;
      } else {
        console.log("Tree activated successfully");
        return true;
      }
    }
  };

  const validateForm = () => {
    const errors: FormErrors = {};

    /*
    if (!["site/Equipment", "site/Worker"].includes(tree.categoryTreeName)) {
      errors.categoryTreeName =
        "Tree name must be site/Equipment or site/Worker";
    }*/

    if (!tree.categoryTreeName) {
      errors.categoryTreeName = "Tree name is required";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!validateForm()) {
      return;
    }
    await postTree();
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>Add Tree</h2>
      {loading && (
        <div className="loader-container">
          <div className="loader" />
        </div>
      )}
      {loading && <label className="post">Adding Tree...</label>}
      <label className="error">{error}</label>
      <label>
        Name:
        <input
          type="text"
          name="categoryTreeName"
          value={tree.categoryTreeName}
          onChange={handleChange}
        />
      </label>
      {formErrors.categoryTreeName && (
        <label className="form-error">{formErrors.categoryTreeName}</label>
      )}
      <input type="submit" value="Add Tree" />
    </form>
  );
};

export default CTreeForm;
