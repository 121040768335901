import { useState, useContext } from "react";
import { Tenant } from "pages/Provision/Provision.type";
import "pages/Provision/Provision.css";
import { signRequest } from "utils/utils";
import { FetchSitesContext } from "pages/Provision/Provision";

interface EditCustomerFormProps {
  edit: boolean;
  selectedTenant: Tenant;
}

const EditCustomerForm: React.FC<EditCustomerFormProps> = ({
  edit,
  selectedTenant,
}) => {
  const [customer, setCustomer] = useState<Tenant>({
    customerName: selectedTenant.customerName,
    address: {
      street: selectedTenant.address.street,
      city: selectedTenant.address.city,
      postalCode: selectedTenant.address.postalCode,
      country: selectedTenant.address.country,
      countryCode: selectedTenant.address.countryCode,
      displayText: selectedTenant.address.displayText,
    },
    phone: selectedTenant.phone,
    customerResName: selectedTenant.customerResName,
    status: selectedTenant.status,
    creationTime: { epochTime: selectedTenant.creationTime.epochTime },
    lastUpdateTime: { epochTime: selectedTenant.lastUpdateTime.epochTime },
  });
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const fetchTenants = useContext(FetchSitesContext);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError("");
    const { name, value } = event.target;
    if (name.includes("address.")) {
      const field = name.split(".")[1];
      setCustomer({
        ...customer,
        address: { ...customer.address, [field]: value },
      });
    } else {
      setCustomer({ ...customer, [name]: value });
    }
  };

  const patchCustomer = async () => {
    setError("");
    setLoading(true);

    const url = `/customers/${selectedTenant.customerResName}`;
    const method = "PATCH";
    const host = "TENANTS";

    const body = {
      customerName: customer.customerName,
      address: {
        street: customer.address.street,
        postalCode: customer.address.postalCode,
        city: customer.address.city,
        countryCode: customer.address.countryCode,
        country: customer.address.country,
        displayText:
          customer.address.street +
          ", " +
          customer.address.city +
          " " +
          customer.address.postalCode,
      },
      phone: customer.phone,
      status: edit ? customer.status : "inactive",
    };

    console.log("body", JSON.stringify(body));
    try {
      const signedRequest = await signRequest(host, url, method, body);
      const response = await fetch(signedRequest.url, signedRequest);

      if (!response.ok) {
        setError("Failed to update customer");
        console.error("Failed to update customer", response);
      } else {
        const createdCustomer = await response.json();
        console.log("Updated customer", createdCustomer);

        createdCustomer.errorMessage
          ? setError("Failed to update customer")
          : fetchTenants();
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      setError("Failed to update customer");
      console.error("Error:", error);
      setLoading(false);
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await patchCustomer();
  };

  const editForm = (
    <>
      <label>
        Name:
        <input
          type="text"
          name="customerName"
          value={customer.customerName}
          onChange={handleChange}
        />
      </label>
      <label>
        Street:
        <input
          type="text"
          name="address.street"
          value={customer.address.street}
          onChange={handleChange}
        />
      </label>
      <label>
        City:
        <input
          type="text"
          name="address.city"
          value={customer.address.city}
          onChange={handleChange}
        />
      </label>
      <label>
        Zipcode:
        <input
          type="text"
          name="address.postalCode"
          value={customer.address.postalCode}
          onChange={handleChange}
        />
      </label>
      <label>
        Country:
        <input
          type="text"
          name="address.country"
          value={customer.address.country}
          onChange={handleChange}
        />
      </label>
      <label>
        Country Code:
        <input
          type="text"
          name="address.countryCode"
          value={customer.address.countryCode}
          onChange={handleChange}
        />
      </label>
      <label>
        Phone:
        <input
          type="text"
          name="phone"
          value={customer.phone}
          onChange={handleChange}
        />
      </label>
    </>
  );

  const deleteForm = (
    <>
      <label>
        Are you sure you want to inactivate {selectedTenant.customerName}?
      </label>
    </>
  );

  return (
    <form onSubmit={handleSubmit}>
      <h2>{edit ? "Edit" : "Inactivate"} Customer</h2>
      {loading && (
        <div className="loader-container">
          <div className="loader" />
        </div>
      )}
      {loading && (
        <label className="post">
          {edit ? "Updating " : "Inactivating "}Customer...
        </label>
      )}
      {error && <label className="error">{error}</label>}
      {edit ? editForm : deleteForm}
      <input
        type="submit"
        value={edit ? "Update Customer" : "Inactivate Customer"}
      />
    </form>
  );
};

export default EditCustomerForm;
