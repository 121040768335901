import { useState, useContext } from "react";
import { Map } from "pages/Provision/Provision.type";
import "pages/Provision/Provision.css";
import { signRequest } from "utils/utils";
import { FetchSiteByOperationsContext } from "pages/Provision/Provision";

interface FormErrors {
  mapName?: string;
}

const MapForm: React.FC<{ account: string; site: string }> = ({
  account,
  site,
}) => {
  const [map, setMap] = useState<Map>({
    accountResName: account,
    siteResName: site,
    mapName: "",
    mapResName: "",
    mapStatus: "active",
    s3BucketName: "",
    s3ObjectKey: "",
    creationTime: { epochTime: 0 },
    lastUpdateTime: { epochTime: 0 },
  });

  const [error, setError] = useState<string>("");
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [loading, setLoading] = useState<boolean>(false);
  const fetchSites = useContext(FetchSiteByOperationsContext);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setMap({ ...map, [name]: value });
  };

  const postMap = async () => {
    setError("");
    setLoading(true);
    const url = `/accounts/${account}/sites/${site}/maps`;
    const method = "POST";
    const host = "MAPS";

    const body = {
      mapName: map.mapName,
    };

    try {
      const signedRequest = await signRequest(host, url, method, body);
      const response = await fetch(signedRequest.url, signedRequest);

      if (!response.ok) {
        setError("Failed to create map");
        console.error("Failed to create map", response);
      } else {
        const createdMap = await response.json();
        console.log("Created map", createdMap);

        createdMap.errorMessage
          ? setError("Failed to create map")
          : fetchSites(account, site, ["maps"]);
        setLoading(false);
      }
    } catch (error) {
      setError("Failed to create map");
      console.error("Error:", error);
      setLoading(false);
    }
  };

  const validateForm = () => {
    const errors: FormErrors = {};

    if (!map.mapName) {
      errors.mapName = "Map name is required";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!validateForm()) {
      return;
    }
    await postMap();
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>Add Map</h2>
      {loading && (
        <div className="loader-container">
          <div className="loader" />
        </div>
      )}
      {loading && <label className="post">Adding Map...</label>}
      <label className="error">{error}</label>
      <label>
        Name:
        <input
          type="text"
          name="mapName"
          value={map.mapName}
          onChange={handleChange}
        />
      </label>
      {formErrors.mapName && (
        <label className="form-error">{formErrors.mapName}</label>
      )}
      <input type="submit" value="Add Map" />
    </form>
  );
};

export default MapForm;
