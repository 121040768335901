import { useState, useContext } from "react";
import { Site } from "pages/Provision/Provision.type";
import "pages/Provision/Provision.css";
import { signRequest } from "utils/utils";
import { FetchSitesContext } from "pages/Provision/Provision";

interface SiteFormProps {
  edit: boolean;
  selectedSite: Site;
}

const EditSiteForm: React.FC<SiteFormProps> = ({ edit, selectedSite }) => {
  const [site, setSite] = useState<Site>({
    customerResName: selectedSite.customerResName,
    accountResName: selectedSite.accountResName,
    siteResName: selectedSite.siteResName,
    siteName: selectedSite.siteName,
    siteStatus: selectedSite.siteStatus,
    siteLocationTechnology: selectedSite.siteLocationTechnology,
    creationTime: { epochTime: selectedSite.creationTime.epochTime },
    lastUpdateTime: { epochTime: selectedSite.lastUpdateTime.epochTime },
  });
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const fetchSites = useContext(FetchSitesContext);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setSite({ ...site, [name]: value });
  };

  const patchSite = async () => {
    setError("");
    setLoading(true);

    const url = `/accounts/${selectedSite.accountResName}/sites/${selectedSite.siteResName}`;
    const method = "PATCH";
    const host = "SITES";

    const body = {
      siteName: site.siteName,
      status: edit ? site.siteStatus : "inactive",
      locationTechnology: site.siteLocationTechnology,
    };

    console.log("Body", JSON.stringify(body));

    try {
      const signedRequest = await signRequest(host, url, method, body);
      const response = await fetch(signedRequest.url, signedRequest);

      if (!response.ok) {
        setError("Failed to update site");
        console.error("Failed to update site", response);
      } else {
        const createdSite = await response.json();
        console.log("Updated site", createdSite);

        createdSite.errorMessage
          ? setError("Failed to update site")
          : fetchSites();

        setLoading(false);
      }
    } catch (error) {
      setError("Failed to update site");
      console.error("Error:", error);
      setLoading(false);
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await patchSite();
  };

  const editForm = (
    <>
      <label>
        Name:
        <input
          type="text"
          name="siteName"
          value={site.siteName}
          onChange={handleChange}
        />
      </label>
      <label>
        Location Technology:
        <input
          type="text"
          name="siteLocationTechnology"
          value={site.siteLocationTechnology}
          onChange={handleChange}
        />
      </label>
    </>
  );

  const deleteForm = <>Are you sure you want to inactivate {site.siteName}?</>;

  return (
    <form onSubmit={handleSubmit}>
      <h2>{edit ? "Update" : "Inactivate"} Site</h2>
      {loading && (
        <div className="loader-container">
          <div className="loader" />
        </div>
      )}
      {loading && (
        <label className="post">
          {edit ? "Updating " : "Inactivating"} Site...
        </label>
      )}
      {error && <label className="error">{error}</label>}
      {edit ? editForm : deleteForm}
      <input type="submit" value={edit ? "Update Site" : "Inactivate Site"} />
    </form>
  );
};

export default EditSiteForm;
