import React, { useState, useMemo, useContext } from "react";
import { Link } from "react-router-dom";
import { Column, useTable, useSortBy } from "react-table";
import {
  Tenant,
  Account,
  Site,
  ProximityRule,
  CategoryTree,
  CategoryPath,
} from "pages/Provision/Provision.type";
import { FetchSiteByOperationsContext } from "pages/Provision/Provision";
import Table from "pages/Provision/tables/Table";

import Modal from "react-modal";
import ProximityRuleDetails from "pages/Provision/details/ProximityRuleDetails";
import ProximityRuleForm from "pages/Provision/forms/ProximityRuleForm";
import EditProximityRuleForm from "pages/Provision/forms/EditProximityForm";

import { FiEdit2 } from "react-icons/fi";
import { FaMagnifyingGlass, FaRegTrashCan } from "react-icons/fa6";
import "pages/Provision/Provision.css";

type SiteTableProps = {
  tenant: Tenant;
  account: Account;
  site: Site;
  trees: CategoryTree[];
  pathes: CategoryPath[];
  rules: ProximityRule[];
};

type columnData = {
  accountResName: string;
  siteResName: string;
  ruleResName: string;
  ruleName: string;
  status: string;
  ruleType: string;
  createTime: string;
  lastUpdateTime: string;
};

const ProximityRuleTable: React.FC<SiteTableProps> = ({
  tenant,
  account,
  site,
  trees,
  pathes,
  rules,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [detailModalIsOpen, setDetailModalIsOpen] = useState(false);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [selectedRule, setSelectedRule] = useState<ProximityRule>(null!);
  const [searchQuery, setSearchQuery] = useState("");
  const fetchSites = useContext(FetchSiteByOperationsContext);

  const data = useMemo(
    () =>
      rules
        .filter((rule) => rule.siteResName === site.siteResName)
        .map((rule) => ({
          ...rule,
          ruleResName: rule.base.ruleResName,
          ruleName: rule.base.ruleName,
          status: rule.base.status,
          createTime: new Date(
            rule.base.creationTime.epochTime,
          ).toLocaleString(),
          lastUpdateTime: new Date(
            rule.base.lastUpdateTime.epochTime,
          ).toLocaleString(),
        }))
        .filter((rule) => {
          return (
            rule.ruleResName
              .toLowerCase()
              .includes(searchQuery.toLowerCase()) ||
            rule.ruleName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            rule.status.toLowerCase().includes(searchQuery.toLowerCase()) ||
            rule.ruleType.toLowerCase().includes(searchQuery.toLowerCase())
          );
        }),
    [tenant, account, site, trees, pathes, rules, searchQuery],
  );

  const columns: Column<columnData>[] = useMemo(
    () => [
      { Header: "RuleResName", accessor: "ruleResName" },
      { Header: "RuleName", accessor: "ruleName" },
      { Header: "Status", accessor: "status" },
      { Header: "RuleType", accessor: "ruleType" },
      { Header: "CreateTime", accessor: "createTime" },
      { Header: "LastUpdateTime", accessor: "lastUpdateTime" },
      {
        Header: "Action",
        Cell: ({ row }: { row: any }) => (
          <div>
            <button
              onClick={() => {
                setSelectedRule(
                  rules.filter(
                    (rule) =>
                      rule.base.ruleResName === row.original.ruleResName,
                  )[0],
                );
                setDetailModalIsOpen(true);
              }}
            >
              <FaMagnifyingGlass />
            </button>
            <button
              onClick={() => {
                setSelectedRule(
                  rules.filter(
                    (rule) =>
                      rule.base.ruleResName === row.original.ruleResName,
                  )[0],
                );
                setEditModalIsOpen(true);
              }}
            >
              <FiEdit2 />
            </button>
            <button
              onClick={() => {
                setSelectedRule(
                  rules.filter(
                    (rule) =>
                      rule.base.ruleResName === row.original.ruleResName,
                  )[0],
                );
                setDeleteModalIsOpen(true);
              }}
            >
              <FaRegTrashCan />
            </button>
          </div>
        ),
      },
    ],
    [tenant, account, site, trees, pathes, rules],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy);

  return (
    <div>
      <div className="header-container">
        <h1>Proximity Rules</h1>
        <button
          onClick={() => {
            setModalIsOpen(true);
          }}
        >
          + Rule
        </button>
      </div>

      <div>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/scrappy/provision">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to={`/scrappy/provision/${tenant.customerResName}`}>
                {tenant.customerName}
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link
                to={`/scrappy/provision/${tenant.customerResName}/${account.accountResName}`}
              >
                {account.accountName}
              </Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {site.siteName}
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Rules
            </li>
          </ol>
        </nav>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        className="modal-content"
        ariaHideApp={false}
      >
        <ProximityRuleForm
          account={account.accountResName}
          site={site.siteResName}
          trees={trees}
          pathes={pathes}
        />
      </Modal>

      <Modal
        isOpen={detailModalIsOpen}
        onRequestClose={() => setDetailModalIsOpen(false)}
        className="modal-content"
        ariaHideApp={false}
      >
        <ProximityRuleDetails
          account={account.accountResName}
          site={site.siteResName}
          trees={trees}
          pathes={pathes}
          selectedRule={selectedRule}
        />
      </Modal>

      <Modal
        isOpen={editModalIsOpen}
        onRequestClose={() => setEditModalIsOpen(false)}
        className="modal-content"
        ariaHideApp={false}
      >
        <EditProximityRuleForm
          edit={true}
          pathes={pathes}
          selectedProximityRule={selectedRule}
        />
      </Modal>

      <Modal
        isOpen={deleteModalIsOpen}
        onRequestClose={() => setDeleteModalIsOpen(false)}
        className="modal-content"
        ariaHideApp={false}
      >
        <EditProximityRuleForm
          edit={false}
          pathes={pathes}
          selectedProximityRule={selectedRule}
        />
      </Modal>

      <div className="table-header-container">
        <input
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <button onClick={() => setSearchQuery("")}>Clear</button>
        <button
          className="button-end"
          onClick={() =>
            fetchSites(account.accountResName, site.siteResName, [
              "proximity-rules",
            ])
          }
        >
          Refresh
        </button>
      </div>
      <Table
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        rows={rows}
        prepareRow={prepareRow}
      />
    </div>
  );
};

export default ProximityRuleTable;
