import { useState, useContext } from "react";
import { Account } from "pages/Provision/Provision.type";
import "pages/Provision/Provision.css";
import { signRequest } from "utils/utils";
import { FetchSitesContext } from "pages/Provision/Provision";

interface AccountFormProps {
  edit: boolean;
  selectedAccount: Account;
}

const EditAccountForm: React.FC<AccountFormProps> = ({
  edit,
  selectedAccount,
}) => {
  const [account, setAccount] = useState<Account>({
    accountResName: selectedAccount.accountResName,
    accountName: selectedAccount.accountName,
    customerResName: selectedAccount.customerResName,
    status: selectedAccount.status,
    creationTime: { epochTime: selectedAccount.creationTime.epochTime },
    lastUpdateTime: { epochTime: selectedAccount.lastUpdateTime.epochTime },
  });
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const fetchSites = useContext(FetchSitesContext);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setAccount({ ...account, [name]: value });
  };

  const patchAccount = async () => {
    setError("");
    setLoading(true);
    const url = `/customers/${account.customerResName}/accounts/${account.accountResName}`;
    const method = "PATCH";
    const host = "TENANTS";

    const body = {
      accountName: account.accountName,
      active: edit ? account.status : "inactive",
    };

    console.log(url);
    console.log("Body", JSON.stringify(body));

    try {
      const signedRequest = await signRequest(host, url, method, body);
      const response = await fetch(signedRequest.url, signedRequest);

      if (!response.ok) {
        setError("Failed to update account");
        console.error("Failed to update account", response.statusText);
      } else {
        const createdAccount = await response.json();
        console.log("Updated account", createdAccount);

        createdAccount.errorMessage
          ? setError("Failed to update account")
          : fetchSites();
        setLoading(false);
      }
    } catch (error) {
      setError("Failed to update account");
      console.error("Error:", error);
      setLoading(false);
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await patchAccount();
  };

  const editForm = (
    <label>
      Name:
      <input
        type="text"
        name="accountName"
        value={account.accountName}
        onChange={handleChange}
      />
    </label>
  );

  const deleteForm = (
    <label>
      Are you sure you want to inactivate {selectedAccount.accountName}?
    </label>
  );

  return (
    <form onSubmit={handleSubmit}>
      <h2>{edit ? "Update" : "Inactivate"} Account (NOT WORKING)</h2>
      {loading && (
        <div className="loader-container">
          <div className="loader" />
        </div>
      )}
      {loading && <label className="post">Adding Account...</label>}
      {error && <label className="error">{error}</label>}
      {edit ? editForm : deleteForm}
      <input
        type="submit"
        value={edit ? "Edit Account" : "Inactivate Account"}
      />
    </form>
  );
};

export default EditAccountForm;
