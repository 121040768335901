import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { Column, useTable, useSortBy } from "react-table";
import {
  Tenant,
  Account,
  Site,
  CategoryTree,
  CategoryPath,
} from "pages/Provision/Provision.type";
import Table from "pages/Provision/tables/Table";

import Modal from "react-modal";
import AddPathForm from "pages/Provision/forms/PathForm";
import EditPathForm from "pages/Provision/forms/EditPathForm";
import { FetchSiteByOperationsContext } from "pages/Provision/Provision";

import { FiEdit2 } from "react-icons/fi";
import { FaRegTrashCan } from "react-icons/fa6";
import "pages/Provision/Provision.css";

type SiteTableProps = {
  tenant: Tenant;
  account: Account;
  site: Site;
  tree: CategoryTree;
  paths: CategoryPath[];
};

type columnData = {
  accountResName: string;
  siteResName: string;
  categoryTreeResName: string;
  categoryPathResName: string;
  categoryPath: string;
  categoryStatus: string;
};

const CategoryPathTable: React.FC<SiteTableProps> = ({
  tenant,
  account,
  site,
  tree,
  paths,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [selectedPath, setSelectedPath] = useState<CategoryPath>(null!);
  const [searchQuery, setSearchQuery] = useState("");
  const fetchSites = useContext(FetchSiteByOperationsContext);

  const data = React.useMemo(
    () =>
      paths
        .filter(
          (path) => path.categoryTreeResName === tree?.categoryTreeResName,
        )
        .map((path) => ({
          ...path,
        }))
        .filter((path) => {
          return (
            path.categoryPathResName
              .toLowerCase()
              .includes(searchQuery.toLowerCase()) ||
            path.categoryPath.toLowerCase().includes(searchQuery.toLowerCase())
          );
        }),
    [tenant, account, site, tree, paths, searchQuery],
  );

  const columns: Column<columnData>[] = React.useMemo(
    () => [
      { Header: "CategoryPathResName", accessor: "categoryPathResName" },
      { Header: "CategoryPath", accessor: "categoryPath" },
      { Header: "Status", accessor: "categoryStatus" },
      {
        Header: "Action",
        Cell: ({ row }: { row: any }) => (
          <div>
            <button
              onClick={() => {
                setSelectedPath(
                  paths.filter(
                    (path) =>
                      path.categoryPathResName ===
                      row.original.categoryPathResName,
                  )[0],
                );
                setEditModalIsOpen(true);
              }}
            >
              <FiEdit2 />
            </button>
            <button
              onClick={() => {
                setSelectedPath(
                  paths.filter(
                    (path) =>
                      path.categoryPathResName ===
                      row.original.categoryPathResName,
                  )[0],
                );
                setDeleteModalIsOpen(true);
              }}
            >
              <FaRegTrashCan />
            </button>
          </div>
        ),
      },
    ],
    [tenant, account, site, tree, paths],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy);

  return (
    <div>
      <div className="header-container">
        <h1>Category Paths</h1>
        <button
          onClick={() => {
            setModalIsOpen(true);
          }}
        >
          + Path
        </button>
      </div>

      <div>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/scrappy/provision">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to={`/scrappy/provision/${tenant.customerResName}`}>
                {tenant.customerName}
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link
                to={`/scrappy/provision/${tenant.customerResName}/${account.accountResName}`}
              >
                {account.accountName}
              </Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <Link
                to={`/scrappy/provision/${tenant.customerResName}/${account.accountResName}/${site.siteResName}/ctree`}
              >
                {site.siteName}
              </Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {tree?.categoryTreeName || "Category Tree"}
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Paths
            </li>
          </ol>
        </nav>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        className="modal-content"
        ariaHideApp={false}
      >
        {tree && (
          <AddPathForm
            account={account.accountResName}
            site={site.siteResName}
            tree={tree?.categoryTreeResName}
          />
        )}
      </Modal>

      <Modal
        isOpen={editModalIsOpen}
        onRequestClose={() => setEditModalIsOpen(false)}
        className="modal-content"
        ariaHideApp={false}
      >
        <EditPathForm edit={true} selectedPath={selectedPath} />
      </Modal>

      <Modal
        isOpen={deleteModalIsOpen}
        onRequestClose={() => setDeleteModalIsOpen(false)}
        className="modal-content"
        ariaHideApp={false}
      >
        <EditPathForm edit={false} selectedPath={selectedPath} />
      </Modal>

      <div className="table-header-container">
        <input
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <button onClick={() => setSearchQuery("")}>Clear</button>
        <button
          className="button-end"
          onClick={() =>
            fetchSites(tenant.customerResName, account.accountResName, [
              "category-trees",
            ])
          }
        >
          Refresh
        </button>
      </div>
      <Table
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        rows={rows}
        prepareRow={prepareRow}
      />
    </div>
  );
};

export default CategoryPathTable;
