import React from "react";
import {
  TiArrowSortedDown,
  TiArrowSortedUp,
  TiArrowUnsorted,
} from "react-icons/ti";

interface TableProps {
  getTableProps: any;
  getTableBodyProps: any;
  headerGroups: any;
  rows: any;
  prepareRow: any;
}

const Table: React.FC<TableProps> = ({
  getTableProps,
  getTableBodyProps,
  headerGroups,
  rows,
  prepareRow,
}) => {
  return (
    <table {...getTableProps()} className="table-container">
      <thead className="table-header-cell">
        {headerGroups.map((headerGroup: any, index: number) => (
          <tr key={`${index}_tr`} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column: any, columnIndex: number) => (
              <th
                key={`${columnIndex}_th`}
                {...column.getHeaderProps()}
                {...column.getSortByToggleProps()}
                className="table-header-cell"
              >
                {column.render("Header")}
                {/* Add a sort direction indicator */}
                <span>
                  {
                    column.isSorted ? (
                      column.isSortedDesc ? (
                        <TiArrowSortedDown />
                      ) : (
                        <TiArrowSortedUp />
                      )
                    ) : (
                      <TiArrowUnsorted />
                    ) /*<TiArrowUnsorted/>*/
                  }
                </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row: any, index: number) => {
          prepareRow(row);
          return (
            <tr key={index} {...row.getRowProps()} className="table-row">
              {row.cells.map((cell: any) => (
                <td {...cell.getCellProps()} className="table-cell">
                  {cell.render("Cell")}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Table;
