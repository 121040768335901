import React, { useState, useMemo, useContext } from "react";
import { Link } from "react-router-dom";
import { Column, useTable, useSortBy } from "react-table";
import { Tenant, Account, Site, Map } from "pages/Provision/Provision.type";
import { FetchSiteByOperationsContext } from "pages/Provision/Provision";
import { FaCloudDownloadAlt } from "react-icons/fa";
import Table from "pages/Provision/tables/Table";

import Modal from "react-modal";
import AddMapForm from "pages/Provision/forms/MapForm";
import DownloadMapForm from "pages/Provision/forms/DownloadMapForm";
import "pages/Provision/Provision.css";

type SiteTableProps = {
  tenant: Tenant;
  account: Account;
  site: Site;
  maps: Map[];
};

type columnData = {
  mapResName: string;
  mapName: string;
  s3BucketName: string;
  s3ObjectKey: string;
  createTime: string;
  lastUpdateTime: string;
};

const MapTable: React.FC<SiteTableProps> = ({
  tenant,
  account,
  site,
  maps,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [downloadModalIsOpen, setDownloadModalIsOpen] = useState(false);
  const [selectedMap, setSelectedMap] = useState<Map>(null!);
  const fetchSites = useContext(FetchSiteByOperationsContext);

  const data = useMemo(
    () =>
      maps
        .filter((map) => map.siteResName === site.siteResName)
        .map((map) => ({
          ...map,
          createTime: new Date(map.creationTime?.epochTime).toLocaleString(),
          lastUpdateTime: new Date(
            map.lastUpdateTime?.epochTime,
          ).toLocaleString(),
        }))
        .filter((map) => {
          return (
            map.mapResName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            map.mapName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            map.s3BucketName
              ?.toLowerCase()
              .includes(searchQuery.toLowerCase()) ||
            map.s3ObjectKey?.toLowerCase().includes(searchQuery.toLowerCase())
          );
        }),
    [tenant, account, site, maps, searchQuery],
  );

  const columns: Column<columnData>[] = React.useMemo(
    () => [
      { Header: "MapResName", accessor: "mapResName" },
      { Header: "MapName", accessor: "mapName" },
      { Header: "S3BucketName", accessor: "s3BucketName" },
      { Header: "S3ObjectKey", accessor: "s3ObjectKey" },
      { Header: "CreateTime", accessor: "createTime" },
      {
        Header: "Actions",
        Cell: ({ row }: { row: any }) => (
          <div>
            <button
              onClick={() => {
                setSelectedMap(
                  maps.filter(
                    (map) => map.mapResName === row.original.mapResName,
                  )[0],
                );
                setDownloadModalIsOpen(true);
              }}
            >
              <FaCloudDownloadAlt />
            </button>
          </div>
        ),
      },
    ],
    [tenant, account, site, maps],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy);

  return (
    <div>
      <div className="header-container">
        <h1>Maps</h1>
        <button onClick={() => setModalIsOpen(true)}>+ Map</button>
      </div>

      <div>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/scrappy/provision">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to={`/scrappy/provision/${tenant.customerResName}`}>
                {tenant.customerName}
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link
                to={`/scrappy/provision/${tenant.customerResName}/${account.accountResName}`}
              >
                {account.accountName}
              </Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {site.siteName}
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Maps
            </li>
          </ol>
        </nav>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        className="modal-content"
        ariaHideApp={false}
      >
        <AddMapForm account={account.accountResName} site={site.siteResName} />
      </Modal>

      <Modal
        isOpen={downloadModalIsOpen}
        onRequestClose={() => setDownloadModalIsOpen(false)}
        className="modal-content"
        ariaHideApp={false}
      >
        <DownloadMapForm selectedMap={selectedMap} />
      </Modal>

      <div className="table-header-container">
        <input
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <button onClick={() => setSearchQuery("")}>Clear</button>
        <button
          className="button-end"
          onClick={() =>
            fetchSites(account.accountResName, site.siteResName, ["maps"])
          }
        >
          Refresh
        </button>
      </div>
      <Table
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        rows={rows}
        prepareRow={prepareRow}
      />
    </div>
  );
};

export default MapTable;
