import React, { useState, useMemo, useContext } from "react";
import { Link } from "react-router-dom";
import { Column, useTable, useSortBy } from "react-table";
import {
  Tenant,
  Account,
  Site,
  Floor,
  CategoryTree,
  ProximityRule,
  Asset,
  Tag,
  Map,
} from "pages/Provision/Provision.type";
import { FetchSitesContext } from "pages/Provision/Provision";
import Table from "pages/Provision/tables/Table";

import Modal from "react-modal";
import AddSiteForm from "pages/Provision/forms/SiteForm";
import EditSiteForm from "pages/Provision/forms/EditSiteForm";

import { FiEdit2 } from "react-icons/fi";
import { FaRegTrashCan } from "react-icons/fa6";

import "pages/Provision/Provision.css";

type SiteTableProps = {
  tenant: Tenant;
  account: Account;
  sites: Site[];
  floors: Floor[];
  categoryTrees: CategoryTree[];
  proximityRules: ProximityRule[];
  assets: Asset[];
  tags: Tag[];
  maps: Map[];
};

type columnData = {
  accountResName: string;
  siteResName: string;
  siteName: string;
  siteStatus: string;
  siteLocationTechnology: string;
  createTime: string;
  lastUpdateTime: string;
  assets: number;
  tags: number;
  floors: number;
  categoryTrees: number;
  proximityRules: number;
  maps: number;
};

const SiteTable: React.FC<SiteTableProps> = ({
  tenant,
  account,
  sites,
  floors,
  categoryTrees,
  proximityRules,
  assets,
  tags,
  maps,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [selectedSite, setSelectedSite] = useState<Site>(null!);
  const [searchQuery, setSearchQuery] = useState("");
  const fetchSites = useContext(FetchSitesContext);
  const data = useMemo(
    () =>
      sites
        .map((site) => ({
          ...site,
          assets: assets.filter(
            (asset) => asset.siteResName === site.siteResName,
          ).length,
          tags: tags.filter((tag) => tag.siteResName === site.siteResName)
            .length,
          floors: floors.filter(
            (floor) => floor.siteResName === site.siteResName,
          ).length,
          categoryTrees: categoryTrees
            .filter(
              (categoryTree) => categoryTree.siteResName === site.siteResName,
            )
            .map((categoryTree) => categoryTree.categoryTreeName).length,
          proximityRules:
            proximityRules &&
            proximityRules
              .filter(
                (proximityRule) =>
                  proximityRule.siteResName === site.siteResName,
              )
              .map((proximityRule) => proximityRule.base.ruleName).length,
          maps: maps.filter((map) => map.siteResName === site.siteResName)
            .length,
          createTime: new Date(site.creationTime.epochTime).toLocaleString(),
          lastUpdateTime: new Date(
            site.lastUpdateTime.epochTime,
          ).toLocaleString(),
        }))
        .filter((site) => {
          return (
            site.accountResName
              .toLowerCase()
              .includes(searchQuery.toLowerCase()) ||
            site.siteResName
              .toLowerCase()
              .includes(searchQuery.toLowerCase()) ||
            site.siteName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            site.siteStatus.toLowerCase().includes(searchQuery.toLowerCase())
          );
        })
        .sort((a, b) => {
          return a.siteName.localeCompare(b.siteName);
        }),

    [
      tenant,
      account,
      sites,
      assets,
      tags,
      floors,
      categoryTrees,
      proximityRules,
      maps,
      searchQuery,
    ],
  );

  const columns: Column<columnData>[] = React.useMemo(
    () => [
      {
        Header: "SiteResName",
        accessor: "siteResName",
      },
      {
        Header: "SiteName",
        accessor: "siteName",
      },
      {
        Header: "Status",
        accessor: "siteStatus",
      },
      /*
      {
        Header: "Tech",
        accessor: "siteLocationTechnology",
      },*/
      {
        Header: "Floors",
        accessor: "floors",
        Cell: ({ row }: { row: any }) => (
          <Link
            to={`/scrappy/provision/${tenant.customerResName}/${account.accountResName}/${row.original.siteResName}/floor`}
          >
            {row.original.floors}
          </Link>
        ),
      },
      {
        Header: "Maps",
        accessor: "maps",
        Cell: ({ row }: { row: any }) => (
          <Link
            to={`/scrappy/provision/${tenant.customerResName}/${account.accountResName}/${row.original.siteResName}/map`}
          >
            {row.original.maps}
          </Link>
        ),
      },
      {
        Header: "CTrees",
        accessor: "categoryTrees",
        Cell: ({ row }: { row: any }) => (
          <Link
            to={`/scrappy/provision/${tenant.customerResName}/${account.accountResName}/${row.original.siteResName}/ctree`}
          >
            {row.original.categoryTrees}
          </Link>
        ),
      },
      {
        Header: "Rules",
        accessor: "proximityRules",
        Cell: ({ row }: { row: any }) => (
          <Link
            to={`/scrappy/provision/${tenant.customerResName}/${account.accountResName}/${row.original.siteResName}/prule`}
          >
            {row.original.proximityRules}
          </Link>
        ),
      },
      {
        Header: "Assets",
        accessor: "assets",
        Cell: ({ row }: { row: any }) => (
          <Link
            to={`/scrappy/provision/${tenant.customerResName}/${account.accountResName}/${row.original.siteResName}/asset`}
          >
            {row.original.assets}
          </Link>
        ),
      },
      {
        Header: "Tags",
        accessor: "tags",
        Cell: ({ row }: { row: any }) => (
          <Link
            to={`/scrappy/provision/${tenant.customerResName}/${account.accountResName}/${row.original.siteResName}/tag`}
          >
            {row.original.tags}
          </Link>
        ),
      },
      {
        Header: "Actions",
        Cell: ({ row }: { row: any }) => (
          <div>
            <button
              onClick={() => {
                setSelectedSite(
                  sites.filter(
                    (site) => site.siteResName === row.original.siteResName,
                  )[0],
                );
                setEditModalIsOpen(true);
              }}
            >
              <FiEdit2 />
            </button>
            <button
              onClick={() => {
                setSelectedSite(
                  sites.filter(
                    (site) => site.siteResName === row.original.siteResName,
                  )[0],
                );
                setDeleteModalIsOpen(true);
              }}
            >
              <FaRegTrashCan />
            </button>
          </div>
        ),
      },
    ],
    [
      tenant,
      account,
      sites,
      assets,
      tags,
      floors,
      categoryTrees,
      proximityRules,
    ],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy);

  return (
    <div>
      <div className="header-container">
        <h1>Sites</h1>
        <button onClick={() => setModalIsOpen(true)}>+ Site</button>
      </div>
      <div>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/scrappy/provision">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to={`/scrappy/provision/${tenant.customerResName}`}>
                {tenant.customerName}
              </Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {account.accountName}
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Sites
            </li>
          </ol>
        </nav>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        className="modal-content"
        ariaHideApp={false}
      >
        <AddSiteForm
          tenant={tenant.customerResName}
          account={account.accountResName}
        />
      </Modal>
      <Modal
        isOpen={editModalIsOpen}
        onRequestClose={() => setEditModalIsOpen(false)}
        className="modal-content"
        ariaHideApp={false}
      >
        <EditSiteForm edit={true} selectedSite={selectedSite} />
      </Modal>
      <Modal
        isOpen={deleteModalIsOpen}
        onRequestClose={() => setDeleteModalIsOpen(false)}
        className="modal-content"
        ariaHideApp={false}
      >
        <EditSiteForm edit={false} selectedSite={selectedSite} />
      </Modal>

      <div className="table-header-container">
        <input
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <button onClick={() => setSearchQuery("")}>Clear</button>
        <button className="button-end" onClick={fetchSites}>
          Refresh
        </button>
      </div>
      <Table
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        rows={rows}
        prepareRow={prepareRow}
      />
    </div>
  );
};

export default SiteTable;
