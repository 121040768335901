import { useState, useContext } from "react";
import { Tag } from "pages/Provision/Provision.type";
import "pages/Provision/Provision.css";
import { signRequest } from "utils/utils";
import { FetchSiteByOperationsContext } from "pages/Provision/Provision";

interface SiteFormProps {
  selectedTag: Tag;
}

const EditAttachmentForm: React.FC<SiteFormProps> = ({ selectedTag }) => {
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const fetchSites = useContext(FetchSiteByOperationsContext);

  const putAssetAttachedTags = async () => {
    setError("");
    setLoading(true);

    const url = `/accounts/${selectedTag.accountResName}/sites/${selectedTag.siteResName}/assets/${selectedTag.asset?.assetResName}/attached-tags`;
    const method = "PUT";
    const host = "ASSETS";
    const body: any = [];

    try {
      const signedRequest = await signRequest(host, url, method, body);
      const response = await fetch(signedRequest.url, signedRequest);

      if (!response.ok) {
        setError("Failed to update asset");
        console.error("Failed to update asset", response);
      } else {
        const createdAsset = await response.json();
        console.log("Updated asset", createdAsset);

        createdAsset.errorMessage
          ? setError("Failed to update asset")
          : fetchSites(selectedTag.accountResName, selectedTag.siteResName, [
              "assets",
              "tags",
            ]);

        setLoading(false);
      }
    } catch (error) {
      setError("Failed to update asset");
      console.error("Error:", error);
      setLoading(false);
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await putAssetAttachedTags();
  };

  const deleteForm = (
    <>
      Are you sure you want to detach {selectedTag.tag.tagName || "this tag"}{" "}
      from {selectedTag.asset?.assetName || "this asset"}?
    </>
  );

  return (
    <form onSubmit={handleSubmit}>
      <h2>Detach Tag from Asset</h2>
      {loading && (
        <div className="loader-container">
          <div className="loader" />
        </div>
      )}
      {loading && <label className="post">Detaching tag ...</label>}
      {error && <label className="error">{error}</label>}
      {deleteForm}
      <input type="submit" value={"Detach Tag"} />
    </form>
  );
};

export default EditAttachmentForm;
