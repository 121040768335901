import React, { useState, useMemo, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Column, useTable, useSortBy } from "react-table";
import { Tenant, Account, Site } from "pages/Provision/Provision.type";
import { FetchSitesContext } from "pages/Provision/Provision";
import Table from "pages/Provision/tables/Table";

import { FaMagnifyingGlass } from "react-icons/fa6";
import { FiEdit2 } from "react-icons/fi";
import { FaRegTrashCan } from "react-icons/fa6";

import Modal from "react-modal";
import AddTenantForm from "pages/Provision/forms/CustomerForm";
import EditTenantForm from "pages/Provision/forms/EditCustomerForm";

import "pages/Provision/Provision.css";

type TenantTableProps = {
  tenants: Tenant[];
  accounts: Account[];
  sites: Site[];
};

type cData = {
  customerResName: string;
  customerName: string;
  status: string;
  address: string;
  phone: string;
  createTime: string;
  lastUpdateTime: string;
  accounts: number;
  sites: number;
};

const TenantTable: React.FC<TenantTableProps> = ({
  tenants,
  accounts,
  sites,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [selectedTenant, setSelectedTenant] = useState<Tenant>(null!);
  const [searchQuery, setSearchQuery] = useState("");
  const fetchSites = useContext(FetchSitesContext);
  const navigate = useNavigate();

  const data = useMemo(
    () =>
      tenants
        .map((tenant) => ({
          ...tenant,
          address: tenant.address.displayText,
          createTime: new Date(tenant.creationTime.epochTime).toLocaleString(),
          lastUpdateTime: new Date(
            tenant.lastUpdateTime.epochTime,
          ).toLocaleString(),
          accounts: accounts.filter(
            (account) => account.customerResName === tenant.customerResName,
          ).length,
          sites: sites.filter(
            (site) => site.customerResName === tenant.customerResName,
          ).length,
        }))
        .filter((tenant) => {
          return (
            tenant.customerResName
              .toLowerCase()
              .includes(searchQuery.toLowerCase()) ||
            tenant.customerName
              ?.toLowerCase()
              .includes(searchQuery.toLowerCase()) ||
            tenant.status?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            tenant.address?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            tenant.phone?.toLowerCase().includes(searchQuery.toLowerCase())
          );
        })
        .sort((a, b) => {
          return a.customerResName.localeCompare(b.customerResName);
        }),
    [tenants, accounts, sites, searchQuery],
  );

  const columns: Column<cData>[] = React.useMemo(
    () => [
      {
        Header: "CustomerResName",
        accessor: "customerResName",
      },
      {
        Header: "CustomerName",
        accessor: "customerName",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Address",
        accessor: "address",
      },
      {
        Header: "Phone",
        accessor: "phone",
      },
      {
        Header: "CreationTime",
        accessor: "createTime",
      },
      {
        Header: "LastUpdateTime",
        accessor: "lastUpdateTime",
      },
      {
        Header: "Accounts",
        accessor: "accounts",
      },
      {
        Header: "Sites",
        accessor: "sites",
      },
      {
        Header: "Actions",
        Cell: ({ row }: { row: any }) => (
          <div>
            <button onClick={() => navigate(`${row.original.customerResName}`)}>
              <FaMagnifyingGlass />
            </button>
            <button
              onClick={() => {
                setSelectedTenant(
                  tenants.filter(
                    (t) => t.customerResName === row.original.customerResName,
                  )[0],
                );
                setEditModalIsOpen(true);
              }}
            >
              <FiEdit2 />
            </button>
            <button
              onClick={() => {
                setSelectedTenant(
                  tenants.filter(
                    (t) => t.customerResName === row.original.customerResName,
                  )[0],
                );
                setDeleteModalIsOpen(true);
              }}
            >
              <FaRegTrashCan />
            </button>
          </div>
        ),
      },
    ],
    [],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy);

  return (
    <div>
      <div className="header-container">
        <h1>Customers</h1>
        <button onClick={() => setModalIsOpen(true)}>+ Customer</button>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        className="modal-content"
        ariaHideApp={false}
      >
        <AddTenantForm />
      </Modal>

      <Modal
        isOpen={editModalIsOpen}
        onRequestClose={() => setEditModalIsOpen(false)}
        className="modal-content"
        ariaHideApp={false}
      >
        <EditTenantForm edit={true} selectedTenant={selectedTenant} />
      </Modal>

      <Modal
        isOpen={deleteModalIsOpen}
        onRequestClose={() => setDeleteModalIsOpen(false)}
        className="modal-content"
        ariaHideApp={false}
      >
        <EditTenantForm edit={false} selectedTenant={selectedTenant} />
      </Modal>

      <div className="table-header-container">
        <input
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <button onClick={() => setSearchQuery("")}>Clear</button>
        <button className="button-end" onClick={fetchSites}>
          Refresh
        </button>
      </div>
      <Table
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        rows={rows}
        prepareRow={prepareRow}
      />
    </div>
  );
};

export default TenantTable;
