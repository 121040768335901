import { useState, useContext } from "react";
import { Account } from "pages/Provision/Provision.type";
import "pages/Provision/Provision.css";
import { signRequest } from "utils/utils";
import { FetchSitesContext } from "pages/Provision/Provision";

interface FormErrors {
  accountName?: string;
}

const AccountForm: React.FC<{ tenant: string }> = ({ tenant }) => {
  const [account, setAccount] = useState<Account>({
    accountResName: "",
    accountName: "",
    customerResName: tenant,
    status: "",
    creationTime: { epochTime: 0 },
    lastUpdateTime: { epochTime: 0 },
  });
  const [error, setError] = useState<string>("");
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [loading, setLoading] = useState<boolean>(false);
  const fetchSites = useContext(FetchSitesContext);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setAccount({ ...account, [name]: value });
  };

  const postAccount = async () => {
    setError("");
    setLoading(true);
    const url = `/customers/${tenant}/accounts`;
    const method = "POST";
    const host = "TENANTS";

    const body = {
      accountName: account.accountName,
    };

    console.log("Body", JSON.stringify(body));

    try {
      const signedRequest = await signRequest(host, url, method, body);
      const response = await fetch(signedRequest.url, signedRequest);

      if (!response.ok) {
        setError("Failed to create account");
        console.error("Failed to create account", response.statusText);
      } else {
        const createdAccount = await response.json();
        console.log("Created account", createdAccount);

        createdAccount.errorMessage
          ? setError("Failed to create account")
          : fetchSites();
        setLoading(false);
      }
    } catch (error) {
      setError("Failed to create account");
      console.error("Error:", error);
      setLoading(false);
    }
  };

  const validateForm = () => {
    const errors: FormErrors = {};

    if (!account.accountName) {
      errors.accountName = "Account name is required";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!validateForm()) {
      return;
    }
    await postAccount();
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>Add Account</h2>
      {loading && (
        <div className="loader-container">
          <div className="loader" />
        </div>
      )}
      {loading && <label className="post">Adding Account...</label>}
      {error && <label className="error">{error}</label>}
      <label>
        Name:
        <input
          type="text"
          name="accountName"
          value={account.accountName}
          onChange={handleChange}
        />
      </label>
      {formErrors.accountName && (
        <label className="form-error">{formErrors.accountName}</label>
      )}
      <input type="submit" value="Add Account" />
    </form>
  );
};

export default AccountForm;
