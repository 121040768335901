import { Routes, Route, Navigate } from "react-router-dom";
import PrivateRoute from "routes/PrivateRoute";

import Login from "pages/Login/Login";
import Layout from "pages/Layout/Layout";
import Provision from "pages/Provision/Provision";
import Config from "pages/Config/Config";
import Report from "pages/Report/Report";

const AppRoutes = () => (
  <Routes>
    <Route path="/login" element={<Login />} />
    <Route path="/scrappy/*" element={<Layout />}>
      <Route
        path="provision"
        element={<PrivateRoute element={<Provision />} />}
      />
      <Route
        path="provision/:tenantParam?/:accountParam?/:siteParam?/:floorOrTreeParam?/:treeParam?"
        element={<PrivateRoute element={<Provision />} />}
      />
      <Route path="config" element={<PrivateRoute element={<Config />} />} />
      <Route path="report" element={<PrivateRoute element={<Report />} />} />
    </Route>
    <Route path="*" element={<Navigate to="/login" replace />} />
  </Routes>
);

export default AppRoutes;
