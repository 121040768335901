import React, { useState, useMemo, useContext } from "react";
import { Link } from "react-router-dom";
import { Column, useTable, useSortBy } from "react-table";
import {
  Tenant,
  Account,
  Site,
  CategoryTree,
  CategoryPath,
  Asset,
  Tag,
} from "pages/Provision/Provision.type";
import { FetchSiteByOperationsContext } from "pages/Provision/Provision";
import Table from "pages/Provision/tables/Table";
import Modal from "react-modal";
import { FaPaperclip, FaUnlink } from "react-icons/fa";
import AttachmentForm from "pages/Provision/forms/AttachmentForm";
import EditAttachmentForm from "pages/Provision/forms/EditAttachmentForm";

type SiteTableProps = {
  tenant: Tenant;
  account: Account;
  site: Site;
  trees: CategoryTree[];
  pathes: CategoryPath[];
  assets: Asset[];
  tags: Tag[];
};

type columnData = {
  assetResName: string;
  assetName: string;
  assetStatus?: string;
  categoryTreeName?: string;
  categoryPathName?: string;
  ownerOrgResName?: string;
  extraProperties?: string;
  tagResName?: string;
  tagName?: string;
};

const AssetTable: React.FC<SiteTableProps> = ({
  tenant,
  account,
  site,
  trees,
  pathes,
  assets,
  tags,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [attachmentModalIsOpen, setAttachmentModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [selectedTag, setSelectedTag] = useState<Tag>(null!);
  const [selectedAsset, setSelectedAsset] = useState<Asset>(null!);
  const fetchSites = useContext(FetchSiteByOperationsContext);

  const data = useMemo(
    () =>
      assets
        .filter((asset) => asset.siteResName === site.siteResName)
        .map((asset) => ({
          ...asset,
          assetResName: asset.assetResName,
          assetName: asset.assetName,
          assetStatus: asset.assetStatus || "",
          categoryTreeName: trees.find(
            (tree) => tree.categoryTreeResName === asset.categoryTreeResName,
          )?.categoryTreeName,
          categoryPathName: pathes.find(
            (path) => path.categoryPathResName === asset.categoryPathResName,
          )?.categoryPath,
          ownerOrgResName: asset.ownerOrgResName || "",
          extraProperties:
            asset.extraProperties
              ?.map((key, value) => `${key}: ${value}`)
              .join(", ") || "",
          tagResName:
            tags.find((tag) => tag.asset?.assetResName === asset.assetResName)
              ?.tag.tagResName || "",
          tagName:
            tags.find((tag) => tag.asset?.assetResName === asset.assetResName)
              ?.tag.tagName || "",
        }))
        .filter((asset) =>
          Object.values(asset).some((value) =>
            String(value).toLowerCase().includes(searchQuery.toLowerCase()),
          ),
        ),
    [tenant, account, site, trees, pathes, tags, assets, searchQuery],
  );

  const columns: Column<columnData>[] = React.useMemo(
    () => [
      {
        Header: "Asset ResName",
        accessor: "assetResName",
      },
      {
        Header: "Asset Name",
        accessor: "assetName",
      },
      {
        Header: "Category Tree",
        accessor: "categoryTreeName",
      },
      {
        Header: "Category Path",
        accessor: "categoryPathName",
      },
      /*
      {
        Header: "Owner Org ResName",
        accessor: "ownerOrgResName",
      },
      {
        Header: "Properties",
        accessor: "extraProperties",
      },
      {
        Header: "Asset Status",
        accessor: "assetStatus",
      },
      */
      {
        Header: "Tag ResName",
        accessor: "tagResName",
      },
      {
        Header: "Tag Name",
        accessor: "tagName",
      },
      {
        Header: "Action",
        Cell: ({ row }: { row: any }) =>
          row.original.tagResName && row.original.assetResName ? (
            <div>
              <button
                onClick={() => {
                  setSelectedTag(
                    tags.filter(
                      (tag) => tag.tag.tagResName === row.original.tagResName,
                    )[0],
                  );

                  setDeleteModalIsOpen(true);
                }}
              >
                <FaUnlink className="small-icon" />
              </button>
            </div>
          ) : (
            <div>
              <button
                onClick={() => {
                  setSelectedAsset(
                    assets.filter(
                      (asset) =>
                        asset.assetResName === row.original.assetResName,
                    )[0],
                  );

                  setAttachmentModalIsOpen(true);
                }}
              >
                <FaPaperclip />
              </button>
            </div>
          ),
      },
    ],
    [tenant, account, site, trees, pathes, tags, assets],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy);

  return (
    <div>
      <div className="header-container">
        <h1>Assets</h1>
      </div>

      <div>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/scrappy/provision">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to={`/scrappy/provision/${tenant.customerResName}`}>
                {tenant.customerName}
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link
                to={`/scrappy/provision/${tenant.customerResName}/${account.accountResName}`}
              >
                {account.accountName}
              </Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {site.siteName}
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Assets
            </li>
          </ol>
        </nav>
      </div>

      <Modal
        isOpen={deleteModalIsOpen}
        onRequestClose={() => setDeleteModalIsOpen(false)}
        className="modal-content"
        ariaHideApp={false}
      >
        <EditAttachmentForm selectedTag={selectedTag} />
      </Modal>

      <Modal
        isOpen={attachmentModalIsOpen}
        onRequestClose={() => setAttachmentModalIsOpen(false)}
        className="modal-content"
        ariaHideApp={false}
      >
        <AttachmentForm selectedAsset={selectedAsset} tags={tags} />
      </Modal>

      <div className="table-header-container">
        <input
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <button onClick={() => setSearchQuery("")}>Clear</button>
        <button
          className="button-end"
          onClick={() =>
            fetchSites(account.accountResName, site.siteResName, [
              "assets",
              "tags",
            ])
          }
        >
          Refresh
        </button>
      </div>
      <Table
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        rows={rows}
        prepareRow={prepareRow}
      />
    </div>
  );
};

export default AssetTable;
