import React, { useState, useMemo, useContext } from "react";
import { Link } from "react-router-dom";
import { Column, useTable, useSortBy } from "react-table";
import {
  Tenant,
  Account,
  Site,
  CategoryTree,
  CategoryPath,
  Tag,
} from "pages/Provision/Provision.type";
import { FetchSiteByOperationsContext } from "pages/Provision/Provision";
import Modal from "react-modal";
import Table from "pages/Provision/tables/Table";
import { FaUnlink } from "react-icons/fa";
import EditAttachmentForm from "pages/Provision/forms/EditAttachmentForm";

type SiteTableProps = {
  tenant: Tenant;
  account: Account;
  site: Site;
  trees: CategoryTree[];
  pathes: CategoryPath[];
  tags: Tag[];
};

type columnData = {
  tagResName: string;
  tagName: string;
  assetResName?: string;
  assetName?: string;
  categoryTreeName?: string;
  categoryPathName?: string;
  ownerOrgResName?: string;
};

const TagTable: React.FC<SiteTableProps> = ({
  tenant,
  account,
  site,
  trees,
  pathes,
  tags,
}) => {
  const [selectedTag, setSelectedTag] = useState<Tag>(null!);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const fetchSites = useContext(FetchSiteByOperationsContext);

  const data = useMemo(
    () =>
      tags
        .filter((tag) => tag.siteResName === site.siteResName)
        .map((tag) => ({
          ...tag,
          tagResName: tag.tag.tagResName,
          tagName: tag.tag.tagName,
          assetResName: tag.asset?.assetResName || "",
          assetName: tag.asset?.assetName || "",
          categoryTreeName:
            trees.find(
              (tree) =>
                tree.categoryTreeResName ===
                tag.asset?.assetCategory.categoryTreeResName,
            )?.categoryTreeName || "",
          categoryPathName:
            pathes.find(
              (path) =>
                path.categoryPathResName ===
                tag.asset?.assetCategory.categoryPathResName,
            )?.categoryPath || "",
        }))
        .filter((tag) =>
          Object.values(tag).some((value) =>
            String(value).toLowerCase().includes(searchQuery.toLowerCase()),
          ),
        ),
    [tenant, account, site, trees, pathes, tags, searchQuery],
  );

  const columns: Column<columnData>[] = React.useMemo(
    () => [
      { Header: "TagResName", accessor: "tagResName" },
      { Header: "TagName", accessor: "tagName" },
      { Header: "AssetName", accessor: "assetName" },
      { Header: "CategoryTreeName", accessor: "categoryTreeName" },
      { Header: "CategoryPathName", accessor: "categoryPathName" },
      {
        Header: "Action",
        Cell: ({ row }: { row: any }) =>
          row.original.tagResName &&
          row.original.assetResName && (
            <div>
              <button
                onClick={() => {
                  setSelectedTag(
                    tags.filter(
                      (tag) => tag.tag.tagResName === row.original.tagResName,
                    )[0],
                  );
                  setDeleteModalIsOpen(true);
                }}
              >
                <FaUnlink className="small-icon" />
              </button>
            </div>
          ),
      },
    ],
    [tenant, account, site, trees, pathes, tags],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy);

  return (
    <div>
      <div className="header-container">
        <h1>Tags</h1>
      </div>

      <div>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/scrappy/provision">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to={`/scrappy/provision/${tenant.customerResName}`}>
                {tenant.customerName}
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link
                to={`/scrappy/provision/${tenant.customerResName}/${account.accountResName}`}
              >
                {account.accountName}
              </Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {site.siteName}
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Tags
            </li>
          </ol>
        </nav>
      </div>

      <Modal
        isOpen={deleteModalIsOpen}
        onRequestClose={() => setDeleteModalIsOpen(false)}
        className="modal-content"
        ariaHideApp={false}
      >
        <EditAttachmentForm selectedTag={selectedTag} />
      </Modal>

      <div className="table-header-container">
        <input
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <button onClick={() => setSearchQuery("")}>Clear</button>
        <button
          className="button-end"
          onClick={() =>
            fetchSites(account.accountResName, site.siteResName, [
              "assets",
              "tags",
            ])
          }
        >
          Refresh
        </button>
      </div>
      <Table
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        rows={rows}
        prepareRow={prepareRow}
      />
    </div>
  );
};

export default TagTable;
