import { useState, useContext } from "react";
import {
  Site,
  Organization,
  User,
  Role,
  Status,
} from "pages/Provision/Provision.type";
import "pages/Provision/Provision.css";
import { signRequest } from "utils/utils";
import { FetchSitesContext } from "pages/Provision/Provision";

interface SiteFormProps {
  edit: boolean;
  selectedUser: User;
  organizations: Organization[];
  sites: Site[];
}

interface FormErrors {
  firstName?: string;
  lastName?: string;
  emailAddress?: string;
  phoneNumber?: string;
  orgName?: string;
  role?: string;
  allowed_site?: string;
  restricted_site?: string;
  status?: string;
}

const RoleOptions = ["manager", "engineer", "subcontractor", "worker", "koto"];

const EditUserForm: React.FC<SiteFormProps> = ({
  edit,
  selectedUser,
  organizations,
  sites,
}) => {
  const [user, setUser] = useState<User>({
    customerResName: selectedUser.customerResName,
    accountResName: selectedUser.accountResName,
    userResName: selectedUser.userResName,
    orgResName: selectedUser.orgResName,
    firstName: selectedUser.firstName,
    lastName: selectedUser.lastName,
    status: selectedUser.status,
    emailAddress: selectedUser.emailAddress,
    phoneNumber: selectedUser.phoneNumber,
    extraProperties: selectedUser.extraProperties,
    userGroups: [],
    managers: [],
    cognitoCorrelatorId: "",
    creationTime: { epochTime: selectedUser.creationTime.epochTime },
    lastUpdateTime: { epochTime: selectedUser.lastUpdateTime.epochTime },
    role: selectedUser.role,
    allowed_site: selectedUser.allowed_site,
    restricted_site: selectedUser.restricted_site,
  });

  const [error, setError] = useState<string>("");
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [loading, setLoading] = useState<boolean>(false);
  const fetchSites = useContext(FetchSitesContext);

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setUser({ ...user, [name]: value });
  };

  const patchUser = async () => {
    setError("");
    setLoading(true);

    const url = `/accounts/${selectedUser.accountResName}/users/${selectedUser.userResName}`;
    const method = "PATCH";
    const host = "USERS";

    let body: {
      orgResName?: string;
      firstName: string;
      lastName: string;
      status: string;
      disableUserAccess: boolean;
      emailAddress: string;
      phoneNumber: string;
      userGroups: any[];
      managers: any[];
      extraProperties: { key: string; value: string }[];
    } = {
      orgResName: user.orgResName,
      firstName: user.firstName,
      lastName: user.lastName,
      status: edit ? user.status : "deleted",
      disableUserAccess: false,
      emailAddress: user.emailAddress,
      phoneNumber: user.phoneNumber,
      userGroups: [],
      managers: [],
      extraProperties: [
        {
          key: "role",
          value: user.role,
        },
        {
          key: "allowed_site",
          value: JSON.stringify(user.allowed_site),
        },
        {
          key: "restricted_site",
          value: JSON.stringify(user.restricted_site),
        },
      ],
    };

    if (!user.orgResName) {
      delete body.orgResName;
    }

    if (!user.allowed_site.length) {
      body.extraProperties = body.extraProperties.filter(
        (prop) => prop.key !== "allowed_site",
      );
    }

    if (!user.restricted_site.length) {
      body.extraProperties = body.extraProperties.filter(
        (prop) => prop.key !== "restricted_site",
      );
    }

    console.log("Body", JSON.stringify(body));

    try {
      const signedRequest = await signRequest(host, url, method, body);
      const response = await fetch(signedRequest.url, signedRequest);

      if (!response.ok) {
        setError("Failed to update user");
        console.error("Failed to update user", response);
      } else {
        const createdUser = await response.json();
        console.log("Created user", createdUser);

        createdUser.errorMessage
          ? setError("Failed to update user")
          : fetchSites();
        setLoading(false);
      }
    } catch (error) {
      setError("Failed to update user");
      console.error("Error:", error);
      setLoading(false);
    }
  };

  const validateForm = () => {
    console.log(user);

    const errors: FormErrors = {};

    if (!user.firstName) {
      errors.firstName = "First name is required";
    }

    if (!user.lastName) {
      errors.lastName = "Last name is required";
    }

    if (!user.emailAddress) {
      errors.emailAddress = "Email address is required";
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(user.emailAddress)) {
      errors.emailAddress = "Invalid email address format";
    }

    const phonePattern = /^\+[1-9]\d{10}$/;
    if (!phonePattern.test(user.phoneNumber)) {
      errors.phoneNumber = "Invalid number. Expected format: +12223334444";
    }

    if (!user.phoneNumber) {
      errors.phoneNumber = "Phone number is required";
    }

    if (!user.role) {
      errors.role = "Role is required";
    }

    if (!user.status) {
      errors.status = "Status is required";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!validateForm()) {
      return;
    }

    await patchUser();
  };

  const editForm = (
    <>
      <label>
        First Name:
        <input
          type="text"
          name="firstName"
          value={user.firstName}
          onChange={handleChangeInput}
        />
        <label className="error">{formErrors.firstName}</label>
      </label>
      <label>
        Last Name:
        <input
          type="text"
          name="lastName"
          value={user.lastName}
          onChange={handleChangeInput}
        />
        <label className="error">{formErrors.lastName}</label>
      </label>
      <label>
        Email:
        <input
          type="text"
          name="emailAddress"
          value={user.emailAddress}
          onChange={handleChangeInput}
        />
        <label className="error">{formErrors.emailAddress}</label>
      </label>
      <label>
        Phone:
        <input
          type="text"
          name="phoneNumber"
          value={user.phoneNumber}
          onChange={handleChangeInput}
        />
        <label className="error">{formErrors.phoneNumber}</label>
      </label>
      <label>
        Firm:
        <select
          name="orgResName"
          value={user.orgResName}
          onChange={(event) => {
            setUser({ ...user, orgResName: event.target.value });
          }}
        >
          <option value="">Select Firm</option>
          {organizations
            .filter((org) => org.status === "active")
            .sort((a, b) => a.orgName.localeCompare(b.orgName))
            .map((org) => (
              <option key={org.orgResName} value={org.orgResName}>
                {org.orgName}
              </option>
            ))}
        </select>
        <label className="error">{formErrors.orgName}</label>
      </label>
      <label>
        Role:
        <select
          name="role"
          value={user.role}
          onChange={(event) => {
            setUser({
              ...user,
              role: event.target.value as Role,
            });
          }}
        >
          <option value="">Select Role</option>
          {RoleOptions.map((role) => (
            <option key={role} value={role}>
              {role}
            </option>
          ))}
        </select>
        <label className="error">{formErrors.role}</label>
      </label>
      <label>
        Select allowed site(s):
        <select
          multiple
          value={user.allowed_site}
          onChange={(event) => {
            const selectedOptions = Array.from(
              event.target.selectedOptions,
              (option) => option.value,
            );
            setUser({
              ...user,
              allowed_site: selectedOptions,
            });
          }}
        >
          {sites
            .filter((site) => site.siteStatus === "active")
            .sort((a, b) => a.siteName.localeCompare(b.siteName))
            .map((site) => (
              <option key={site.siteResName} value={site.siteResName}>
                {site.siteName}
              </option>
            ))}
        </select>
        <label className="error">{formErrors.allowed_site}</label>
      </label>
      <label>
        Select restricted site(s):
        <select
          multiple
          value={user.restricted_site}
          onChange={(event) => {
            const selectedOptions = Array.from(
              event.target.selectedOptions,
              (option) => option.value,
            );
            setUser({
              ...user,
              restricted_site: selectedOptions,
            });
          }}
        >
          {sites
            .filter((site) => site.siteStatus === "active")
            .sort((a, b) => a.siteName.localeCompare(b.siteName))
            .map((site) => (
              <option key={site.siteResName} value={site.siteResName}>
                {site.siteName}
              </option>
            ))}
        </select>
        <label className="error">{formErrors.restricted_site}</label>
      </label>
      <label>
        Status:
        <select
          name="status"
          value={user.status}
          onChange={(event) => {
            setUser({ ...user, status: event.target.value as Status });
          }}
        >
          <option value="active">Active</option>
          <option value="inactive">Inactive</option>
        </select>
        <label className="error">{formErrors.status}</label>
      </label>
    </>
  );

  const deleteForm = <>Are you sure you want to delete {user.emailAddress}?</>;

  return (
    <form onSubmit={handleSubmit}>
      <h2>{edit ? "Update " : "Delete "} ZPS User</h2>
      {loading && (
        <div className="loader-container">
          <div className="loader" />
        </div>
      )}
      {loading && <label className="post">Updating ZPS User...</label>}
      {error && <label className="error">{error}</label>}
      {edit ? editForm : deleteForm}
      <input
        type="submit"
        value={edit ? "Update ZPS User" : "Delete ZPS User"}
      />
    </form>
  );
};

export default EditUserForm;
