import React, { useState, useMemo, useContext } from "react";
import { Link } from "react-router-dom";
import { Column, useTable, useSortBy } from "react-table";
import {
  Tenant,
  Account,
  User,
  Site,
  Organization,
} from "pages/Provision/Provision.type";
import { FetchSitesContext } from "pages/Provision/Provision";
import Table from "pages/Provision/tables/Table";

import { FaRegTrashCan } from "react-icons/fa6";
import { FiEdit2 } from "react-icons/fi";

import "pages/Provision/Provision.css";
import Modal from "react-modal";
import AddAccountForm from "pages/Provision/forms/AccountForm";
import EditAccountForm from "pages/Provision/forms/EditAccountForm";

type AccountTableProps = {
  tenant: Tenant;
  accounts: Account[];
  users: User[];
  sites: Site[];
  organizations: Organization[];
};

type columnData = {
  customerResName: string;
  accountResName: string;
  accountName: string;
  status: string;
  createTime: string;
  lastUpdateTime: string;
  sites: number;
  users: number;
  organizations: number;
};

const AccountTable: React.FC<AccountTableProps> = ({
  tenant,
  accounts,
  users,
  sites,
  organizations,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState<Account>(null!);
  const [searchQuery, setSearchQuery] = useState("");
  const fetchSites = useContext(FetchSitesContext);

  const data = useMemo(
    () =>
      accounts
        .map((account) => ({
          ...account,
          createTime: new Date(account.creationTime.epochTime).toLocaleString(),
          lastUpdateTime: new Date(
            account.lastUpdateTime.epochTime,
          ).toLocaleString(),
          sites: sites.filter(
            (site) => site.accountResName === account.accountResName,
          ).length,
          users: users.filter(
            (user) => user.accountResName === account.accountResName,
          ).length,
          organizations: organizations.filter(
            (org) =>
              org.accountResName === account.accountResName &&
              ["active", "inactive"].includes(org.status),
          ).length,
        }))
        .filter((account) => {
          return (
            account.customerResName
              .toLowerCase()
              .includes(searchQuery.toLowerCase()) ||
            account.accountResName
              .toLowerCase()
              .includes(searchQuery.toLowerCase()) ||
            account.accountName
              .toLowerCase()
              .includes(searchQuery.toLowerCase()) ||
            account.status.toLowerCase().includes(searchQuery.toLowerCase())
          );
        })
        .sort((a, b) => {
          return a.accountResName.localeCompare(b.accountResName);
        }),

    [tenant, accounts, sites, searchQuery],
  );

  const columns: Column<columnData>[] = React.useMemo(
    () => [
      {
        Header: "AccountResName",
        accessor: "accountResName",
      },
      {
        Header: "AccountName",
        accessor: "accountName",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "CreationTime",
        accessor: "createTime",
      },
      {
        Header: "LastUpdateTime",
        accessor: "lastUpdateTime",
      },
      {
        Header: "Firms",
        accessor: "organizations",
        Cell: ({ row }: { row: any }) => (
          <Link
            to={`/scrappy/provision/${tenant.customerResName}/${row.original.accountResName}/none/organization`}
          >
            {row.original.organizations}
          </Link>
        ),
      },
      {
        Header: "Users",
        accessor: "users",
        Cell: ({ row }: { row: any }) => (
          <Link
            to={`/scrappy/provision/${tenant.customerResName}/${row.original.accountResName}/none/user`}
          >
            {row.original.users}
          </Link>
        ),
      },
      {
        Header: "Sites",
        accessor: "sites",
        Cell: ({ row }: { row: any }) => (
          <Link
            to={`/scrappy/provision/${tenant.customerResName}/${row.original.accountResName}`}
          >
            {row.original.sites}
          </Link>
        ),
      },
      {
        Header: "Actions",
        Cell: ({ row }: { row: any }) => (
          <div>
            <button
              onClick={() => {
                setSelectedAccount(
                  accounts.filter(
                    (account) =>
                      account.accountResName === row.original.accountResName,
                  )[0],
                );
                setEditModalIsOpen(true);
              }}
            >
              <FiEdit2 />
            </button>
            <button
              onClick={() => {
                setSelectedAccount(
                  accounts.filter(
                    (account) =>
                      account.accountResName === row.original.accountResName,
                  )[0],
                );
                setDeleteModalIsOpen(true);
              }}
            >
              <FaRegTrashCan />
            </button>
          </div>
        ),
      },
    ],
    [],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy);

  return (
    <div>
      <div className="header-container">
        <h1>Accounts</h1>
        <button
          onClick={() => {
            setModalIsOpen(true);
          }}
        >
          + Account
        </button>
      </div>
      <div>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/scrappy/provision">Home</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {tenant.customerName}
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Accounts
            </li>
          </ol>
        </nav>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        className="modal-content"
        ariaHideApp={false}
      >
        <AddAccountForm tenant={tenant.customerResName} />
      </Modal>

      <Modal
        isOpen={editModalIsOpen}
        onRequestClose={() => setEditModalIsOpen(false)}
        className="modal-content"
        ariaHideApp={false}
      >
        <EditAccountForm edit={true} selectedAccount={selectedAccount} />
      </Modal>

      <Modal
        isOpen={deleteModalIsOpen}
        onRequestClose={() => setDeleteModalIsOpen(false)}
        className="modal-content"
        ariaHideApp={false}
      >
        <EditAccountForm edit={false} selectedAccount={selectedAccount} />
      </Modal>

      <div className="table-header-container">
        <input
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <button onClick={() => setSearchQuery("")}>Clear</button>
        <button className="button-end" onClick={fetchSites}>
          Refresh
        </button>
      </div>
      <Table
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        rows={rows}
        prepareRow={prepareRow}
      />
    </div>
  );
};

export default AccountTable;
