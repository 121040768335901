import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";

import "pages/Layout/Layout.css"; // import the CSS file

import { LuHammer } from "react-icons/lu";
import { TbReportSearch } from "react-icons/tb";

import { CognitoUserPool } from "amazon-cognito-identity-js";

const userPool = new CognitoUserPool({
  UserPoolId: import.meta.env.VITE_AWS_COGNITO_USER_POOL_ID,
  ClientId: import.meta.env.VITE_AWS_COGNITO_USER_POOL_APP_CLIENT_ID,
});

const Layout: React.FC = () => {
  const navigate = useNavigate();

  const signOut = (e: React.MouseEvent) => {
    e.preventDefault();
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser !== null) {
      cognitoUser.signOut();
      navigate("/login");
    } else {
      console.error("No user is currently signed in");
    }
  };

  return (
    <div className="layout-container">
      <header className="header">
        <img
          className="logo"
          src="https://zainartech.com/images/zainar-logo-white.png"
          alt="Logo"
        />
        <nav className="nav">
          <ul>
            <li>
              <Link to="/scrappy/provision">
                <LuHammer className="icon" />
                Provision
              </Link>
            </li>
            {/*
            <li>
              <Link to="/scrappy/config">
                <GrConfigure className="icon" />
                Config
              </Link>
            </li>
            */}
            <li>
              <Link to="/scrappy/report">
                <TbReportSearch className="icon" />
                Report
              </Link>
            </li>
          </ul>
        </nav>
        <button className="logout-button" onClick={signOut}>
          {localStorage.getItem("username")?.replace(/"/g, "").toUpperCase()}{" "}
          <div>Sign out</div>
        </button>
      </header>
      <main className="main">
        <Outlet />
      </main>
    </div>
  );
};

export default Layout;
