import React, { useState, useMemo, useContext } from "react";
import { Link } from "react-router-dom";
import { Column, useTable, useSortBy } from "react-table";
import {
  Tenant,
  Account,
  Site,
  Organization,
  User,
} from "pages/Provision/Provision.type";
import { FetchSitesContext } from "pages/Provision/Provision";
import Table from "pages/Provision/tables/Table";

import Modal from "react-modal";
import UserDetails from "pages/Provision/details/UserDetails";
import UserForm from "pages/Provision/forms/UserForm";
import EditUserForm from "pages/Provision/forms/EditUserForm";

import { FiEdit2 } from "react-icons/fi";
import { FaMagnifyingGlass, FaRegTrashCan } from "react-icons/fa6";

type SiteTableProps = {
  tenant: Tenant;
  account: Account;
  sites: Site[];
  organizations: Organization[];
  users: User[];
};

type columnData = {
  userResName: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber: string;
  createTime: string;
  lastUpdateTime: string;
  status: string;
  extraProperties: string;
  orgName: string;
};

const UserTable: React.FC<SiteTableProps> = ({
  tenant,
  account,
  organizations,
  sites,
  users,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [detailModalIsOpen, setDetailModalIsOpen] = useState(false);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User>(null!);
  const [searchQuery, setSearchQuery] = useState("");
  const fetchSites = useContext(FetchSitesContext);

  const data = useMemo(
    () =>
      users
        .map((user) => ({
          ...user,
          userResName: user.userResName,
          firstName: user.firstName,
          lastName: user.lastName,
          emailAddress: user.emailAddress,
          phoneNumber: user.phoneNumber,
          orgName: organizations.filter(
            (org) => org.orgResName === user.orgResName,
          )[0]?.orgName,
          createTime: new Date(user.creationTime.epochTime).toLocaleString(),
          lastUpdateTime: new Date(
            user.lastUpdateTime.epochTime,
          ).toLocaleString(),
          status: user.status,
          extraProperties: user.extraProperties
            ? user.extraProperties
                .map((prop) => `${prop.key}: ${prop.value}`)
                .join(", ")
            : "",
        }))
        .filter((user) =>
          Object.values(user).some((value) =>
            String(value).toLowerCase().includes(searchQuery.toLowerCase()),
          ),
        ),
    [tenant, account, organizations, users, searchQuery],
  );

  const columns: Column<columnData>[] = React.useMemo(
    () => [
      {
        Header: "UserResName",
        accessor: "userResName",
      },
      {
        Header: "First Name",
        accessor: "firstName",
      },
      {
        Header: "Last Name",
        accessor: "lastName",
      },
      {
        Header: "Email Address",
        accessor: "emailAddress",
      },
      {
        Header: "Org Name",
        accessor: "orgName",
      },
      {
        Header: "Extra Properties",
        accessor: "extraProperties",
      },
      {
        Header: "Create Time",
        accessor: "createTime",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Action",
        Cell: ({ row }: { row: any }) => (
          <div>
            <button
              onClick={() => {
                setSelectedUser(
                  users.filter(
                    (user) => user.userResName === row.original.userResName,
                  )[0],
                );
                setDetailModalIsOpen(true);
              }}
            >
              <FaMagnifyingGlass />
            </button>
            <button
              onClick={() => {
                setSelectedUser(
                  users.filter(
                    (user) => user.userResName === row.original.userResName,
                  )[0],
                );
                setEditModalIsOpen(true);
              }}
            >
              <FiEdit2 />
            </button>
            <button
              onClick={() => {
                setSelectedUser(
                  users.filter(
                    (user) => user.userResName === row.original.userResName,
                  )[0],
                );
                setDeleteModalIsOpen(true);
              }}
            >
              <FaRegTrashCan />
            </button>
          </div>
        ),
      },
    ],
    [tenant, account, organizations, sites, users],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy);

  return (
    <div>
      <div className="header-container">
        <h1>Users</h1>
        <button
          onClick={() => {
            setModalIsOpen(true);
          }}
        >
          + ZPS User
        </button>
      </div>

      <div>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/scrappy/provision">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to={`/scrappy/provision/${tenant.customerResName}`}>
                {tenant.customerName}
              </Link>
            </li>
            <li className="breadcrumb-item">{account.accountName}</li>
            <li className="breadcrumb-item active" aria-current="page">
              Users
            </li>
          </ol>
        </nav>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        className="modal-content"
        ariaHideApp={false}
      >
        <UserForm
          customerResName={tenant.customerResName}
          accountResName={account.accountResName}
          organizations={organizations}
          sites={sites}
        />
      </Modal>

      <Modal
        isOpen={detailModalIsOpen}
        onRequestClose={() => setDetailModalIsOpen(false)}
        className="modal-content"
        ariaHideApp={false}
      >
        <UserDetails
          tenant={tenant.customerName}
          account={account.accountName}
          sites={sites}
          organizations={organizations}
          selectedUser={selectedUser}
        />
      </Modal>

      <Modal
        isOpen={editModalIsOpen}
        onRequestClose={() => setEditModalIsOpen(false)}
        className="modal-content"
        ariaHideApp={false}
      >
        <EditUserForm
          edit={true}
          selectedUser={selectedUser}
          organizations={organizations}
          sites={sites}
        />
      </Modal>

      <Modal
        isOpen={deleteModalIsOpen}
        onRequestClose={() => setDeleteModalIsOpen(false)}
        className="modal-content"
        ariaHideApp={false}
      >
        <EditUserForm
          edit={false}
          selectedUser={selectedUser}
          organizations={organizations}
          sites={sites}
        />
      </Modal>

      <div className="table-header-container">
        <input
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <button onClick={() => setSearchQuery("")}>Clear</button>
        <button className="button-end" onClick={fetchSites}>
          Refresh
        </button>
      </div>
      <Table
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        rows={rows}
        prepareRow={prepareRow}
      />
    </div>
  );
};

export default UserTable;
