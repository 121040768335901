import { useState, useContext } from "react";
import { Tenant } from "pages/Provision/Provision.type";
import "pages/Provision/Provision.css";
import { signRequest } from "utils/utils";
import { FetchSitesContext } from "pages/Provision/Provision";

interface FormErrors {
  customerName?: string;
  street?: string;
  postalCode?: string;
  city?: string;
  countryCode?: string;
  country?: string;
  phone?: string;
}

const CustomerForm: React.FC = () => {
  const [customer, setCustomer] = useState<Tenant>({
    customerName: "",
    address: {
      street: "",
      city: "",
      postalCode: "",
      country: "",
      countryCode: "",
      displayText: "",
    },
    phone: "",
    customerResName: "",
    status: "",
    creationTime: { epochTime: 0 },
    lastUpdateTime: { epochTime: 0 },
  });
  const [error, setError] = useState<string>("");
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [loading, setLoading] = useState<boolean>(false);
  const fetchTenants = useContext(FetchSitesContext);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError("");
    const { name, value } = event.target;
    if (name.includes("address.")) {
      const field = name.split(".")[1];
      setCustomer({
        ...customer,
        address: { ...customer.address, [field]: value },
      });
    } else {
      setCustomer({ ...customer, [name]: value });
    }
  };

  const postCustomer = async () => {
    setError("");
    setLoading(true);

    const url = "/customers";
    const method = "POST";
    const host = "TENANTS";

    const body = {
      customerName: customer.customerName,
      address: {
        street: customer.address.street,
        postalCode: customer.address.postalCode,
        city: customer.address.city,
        countryCode: customer.address.countryCode,
        country: customer.address.country,
        displayText:
          customer.address.street +
          ", " +
          customer.address.city +
          " " +
          customer.address.postalCode,
      },
      phone: customer.phone,
    };

    console.log("body", JSON.stringify(body));
    try {
      const signedRequest = await signRequest(host, url, method, body);
      const response = await fetch(signedRequest.url, signedRequest);

      if (!response.ok) {
        setError("Failed to create customer");
        console.error("Failed to create customer", response);
      } else {
        const createdCustomer = await response.json();
        console.log("Created customer", createdCustomer);

        createdCustomer.errorMessage
          ? setError("Failed to create customer")
          : fetchTenants();
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      setError("Failed to create customer");
      console.error("Error:", error);
      setLoading(false);
    }
  };

  const validate = () => {
    let tempErrors = {};
    tempErrors = {
      customerName: customer.customerName ? "" : "Name is required",
      street: customer.address.street ? "" : "Street is required",
      postalCode: customer.address.postalCode ? "" : "Code is required",
      city: customer.address.city ? "" : "City is required",
      countryCode: customer.address.countryCode
        ? ""
        : "Country code is required",
      country: customer.address.country ? "" : "Country is required",
      phone: customer.phone ? "" : "Phone is required",
    };
    setFormErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === "");
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (validate()) {
      await postCustomer();
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>Add Customer</h2>
      {loading && (
        <div className="loader-container">
          <div className="loader" />
        </div>
      )}
      {loading && <label className="post">Adding Customer...</label>}
      {error && <label className="error">{error}</label>}
      <label>
        Name:
        <input
          type="text"
          name="customerName"
          value={customer.customerName}
          onChange={handleChange}
        />
      </label>
      {formErrors.customerName && (
        <label className="form-error">{formErrors.customerName}</label>
      )}
      <label>
        Street:
        <input
          type="text"
          name="address.street"
          value={customer.address.street}
          onChange={handleChange}
        />
      </label>
      {formErrors.street && (
        <label className="form-error">{formErrors.street}</label>
      )}
      <label>
        City:
        <input
          type="text"
          name="address.city"
          value={customer.address.city}
          onChange={handleChange}
        />
      </label>
      {formErrors.city && (
        <label className="form-error">{formErrors.city}</label>
      )}
      <label>
        Zipcode:
        <input
          type="text"
          name="address.postalCode"
          value={customer.address.postalCode}
          onChange={handleChange}
        />
      </label>
      {formErrors.postalCode && (
        <label className="form-error">{formErrors.postalCode}</label>
      )}
      <label>
        Country:
        <input
          type="text"
          name="address.country"
          value={customer.address.country}
          onChange={handleChange}
        />
      </label>
      {formErrors.country && (
        <label className="form-error">{formErrors.country}</label>
      )}
      <label>
        Country Code:
        <input
          type="text"
          name="address.countryCode"
          value={customer.address.countryCode}
          onChange={handleChange}
        />
      </label>
      {formErrors.countryCode && (
        <label className="form-error">{formErrors.countryCode}</label>
      )}
      <label>
        Phone:
        <input
          type="text"
          name="phone"
          value={customer.phone}
          onChange={handleChange}
        />
      </label>
      {formErrors.phone && (
        <label className="form-error">{formErrors.phone}</label>
      )}
      <input type="submit" value="Add Customer" />
    </form>
  );
};

export default CustomerForm;
