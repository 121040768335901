import { useState, useContext } from "react";
import { Site } from "pages/Provision/Provision.type";
import "pages/Provision/Provision.css";
import { signRequest } from "utils/utils";
import { FetchSitesContext } from "pages/Provision/Provision";

interface FormErrors {
  siteName?: string;
}

const SiteForm: React.FC<{ tenant: string; account: string }> = ({
  tenant,
  account,
}) => {
  const [site, setSite] = useState<Site>({
    customerResName: tenant,
    accountResName: account,
    siteResName: "",
    siteName: "",
    siteStatus: "active",
    siteLocationTechnology: "wifi",
    creationTime: { epochTime: 0 },
    lastUpdateTime: { epochTime: 0 },
  });
  const [error, setError] = useState<string>("");
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [loading, setLoading] = useState<boolean>(false);
  const fetchSites = useContext(FetchSitesContext);
  console.log(account);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setSite({ ...site, [name]: value });
  };

  const activateSite = async (id: string) => {
    const url = `/accounts/${account}/sites/${id}`;
    const method = "PATCH";
    const host = "SITES";

    const body = {
      siteName: site.siteName,
      locationTechnology: "wifi",
      status: "active",
    };

    const signedPatchRequest = await signRequest(host, url, method, body);
    const patchResponse = await fetch(
      signedPatchRequest.url,
      signedPatchRequest,
    );

    if (!patchResponse.ok) {
      console.error("Failed to update site", patchResponse);
      return false;
    } else {
      const updatedSite = await patchResponse.json();
      console.log("Updated site", updatedSite);

      if (updatedSite.errorMessage) {
        setError("Failed to activate site");
        return false;
      } else {
        console.log("Site updated successfully");
        return true;
      }
    }
  };

  const postSite = async () => {
    setError("");
    setLoading(true);

    const url = `/accounts/${account}/sites`;
    const method = "POST";
    const host = "SITES";

    const body = {
      siteName: site.siteName,
      locationTechnology: "wifi",
    };

    console.log("body", JSON.stringify(body));

    try {
      const signedRequest = await signRequest(host, url, method, body);
      const response = await fetch(signedRequest.url, signedRequest);

      if (!response.ok) {
        setError("Failed to create site");
        console.error("Failed to create site", response);
      } else {
        const createdSite = await response.json();
        console.log("Created site", createdSite);

        if (createdSite.errorMessage) {
          setError("Failed to create site");
        } else {
          const success = await activateSite(
            createdSite.replace("Created site ", ""),
          );
          success && fetchSites();
        }

        setLoading(false);
      }
    } catch (error) {
      setError("Failed to create site");
      console.error("Error:", error);
      setLoading(false);
    }
  };

  const validateForm = () => {
    const errors: FormErrors = {};

    if (!site.siteName) {
      errors.siteName = "Site name is required";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!validateForm()) {
      return;
    }
    await postSite();
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>Add Site</h2>
      {loading && (
        <div className="loader-container">
          <div className="loader" />
        </div>
      )}
      {loading && <label className="post">Adding Site...</label>}
      {error && <label className="error">{error}</label>}
      <label>
        Name:
        <input
          type="text"
          name="siteName"
          value={site.siteName}
          onChange={handleChange}
        />
      </label>
      {formErrors.siteName && (
        <label className="form-error">{formErrors.siteName}</label>
      )}
      <input type="submit" value="Add Site" />
    </form>
  );
};

export default SiteForm;
