import { useState, useContext } from "react";
import { CategoryTree } from "pages/Provision/Provision.type";
import "pages/Provision/Provision.css";
import { signRequest } from "utils/utils";
import { FetchSiteByOperationsContext } from "pages/Provision/Provision";

interface FormErrors {
  categoryTreeName?: string;
}

interface EditCTreeFormProps {
  edit: boolean;
  selectedTree: CategoryTree;
}

const EditCTreeForm: React.FC<EditCTreeFormProps> = ({
  edit,
  selectedTree,
}) => {
  const [tree, setTree] = useState<CategoryTree>({
    accountResName: selectedTree.accountResName,
    siteResName: selectedTree.siteResName,
    categoryTreeName: selectedTree.categoryTreeName,
    categoryTreeResName: selectedTree.categoryTreeResName,
    categoryTreeStatus: selectedTree.categoryTreeStatus,
    categoryPathList: selectedTree.categoryPathList,
  });
  const [error, setError] = useState<string>("");
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [loading, setLoading] = useState<boolean>(false);
  const fetchSites = useContext(FetchSiteByOperationsContext);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setTree({ ...tree, [name]: value });
  };

  const patchTree = async () => {
    setError("");
    setLoading(true);
    const url = `/accounts/${selectedTree.accountResName}/sites/${selectedTree.siteResName}/assets/category-trees/${selectedTree.categoryTreeResName}`;
    const method = "PATCH";
    const host = "ASSETS";

    const body = {
      categoryTreeName: tree.categoryTreeName,
      categoryTreeStatus: edit ? tree.categoryTreeStatus : "inactive",
    };

    try {
      const signedRequest = await signRequest(host, url, method, body);
      const response = await fetch(signedRequest.url, signedRequest);

      if (!response.ok) {
        setError("Failed to update tree");
        console.error("Failed to update tree", response);
      } else {
        const createdTree = await response.json();
        console.log("Updated tree", createdTree);

        createdTree.errorMessage
          ? setError("Failed to update tree")
          : fetchSites(selectedTree.accountResName, selectedTree.siteResName, [
              "category-trees",
            ]);
        setLoading(false);
      }
    } catch (error) {
      setError("Failed to update tree");
      console.error("Error:", error);
      setLoading(false);
    }
  };

  const validateForm = () => {
    const errors: FormErrors = {};

    if (!tree.categoryTreeName) {
      errors.categoryTreeName = "Tree name is required";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!validateForm()) {
      return;
    }
    await patchTree();
  };

  const editForm = (
    <>
      <label>
        Name:
        <input
          type="text"
          name="categoryTreeName"
          value={tree.categoryTreeName}
          onChange={handleChange}
        />
      </label>
      {formErrors.categoryTreeName && (
        <label className="form-error">{formErrors.categoryTreeName}</label>
      )}
    </>
  );

  const deleteForm = (
    <>
      <label>
        Are you sure you want to inactivate {selectedTree.categoryTreeName}?
      </label>
    </>
  );

  return (
    <form onSubmit={handleSubmit}>
      <h2>{edit ? "Update" : "Inactivate"} Tree</h2>
      {loading && (
        <div className="loader-container">
          <div className="loader" />
        </div>
      )}
      {loading && (
        <label className="post">
          {edit ? "Updating " : "Inactivating "} Tree...
        </label>
      )}
      <label className="error">{error}</label>
      {edit ? editForm : deleteForm}
      <input type="submit" value={edit ? "Update Tree" : "Inactivate Tree"} />
    </form>
  );
};

export default EditCTreeForm;
